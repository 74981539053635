import React, { useCallback, useEffect, useState } from 'react';
import RadioRendering from './OptionsRendering/RadioRendering';
import SelectRendering from './OptionsRendering/SelectRendering';
import ButtonRendering from './OptionsRendering/ButtonRendering';
import OptionsList from '../Options/OptionsList';
import OptionRenderingModel, {
  htmlRenderingType,
} from '../../Models/OptionRenderingModel';
import ProductModel from '../../Models/ProductModel';
import SessionModel from '../../Models/SessionModel';
import SessionOptionModel from '../../Models/SessionOptionModel';
import OptionModel from '../../Models/OptionModel';
import OptionRenderingValuesModel from '../../Models/OptionRenderingValuesModel';
import './MultipleChoice.css';

type OptionTableEntry = {
  [key: string]: OptionRenderingValuesModel[] | htmlRenderingType;
} & {
  render: htmlRenderingType;
};

type RadioValueType = {
  [key: string]: string;
};

export interface loadedSessionOptionsInterface {
  options: SessionOptionModel[];
  productId: number;
  sessionId: number;
}

export interface RenderingProps {
  option: string;
  currentStep: number;
  radioValue: RadioValueType[];
  values: OptionRenderingValuesModel[];
  optionArray?: string[];
  getRadioValue: (value: string, step: number, event: string) => void;
  getOption: (value: OptionRenderingValuesModel, step: number) => void;
  getOptionDesc: (value: string, step: number) => void;
}

interface TicketOptionsProps extends OptionsChildProps {
  option: OptionRenderingModel;
  loadedSessionOptions?: {
    options: SessionOptionModel[];
    productId: number;
    sessionId: number;
  }[];
  sessionOptionsList?: {
    error: boolean;
    loading: boolean;
    options: SessionOptionModel[];
  };
  totalAllQty: number;
  exchangeQty?: number;
}

export interface OptionsChildProps {
  product: ProductModel;
  session: SessionModel | null;
  handleChangeQtyOption: (
    item: OptionModel,
    quantity: number,
    session: SessionModel
  ) => void;
  handleChangeQtySession: (session: SessionModel, quantity: number) => void;
  isDeletable?: boolean;
  selectedSessions?: SessionModel[];
  selectedOptions: OptionModel[];
}

const TicketOptions = (props: TicketOptionsProps) => {
  const [optionTable, setOptionTable] = useState<OptionTableEntry[]>([]);
  const [radioValue, setRadioValue] = useState<RadioValueType[]>([]);
  let optionArray = [];

  const renderOption = useCallback(
    (
      option: OptionRenderingValuesModel[],
      key: string,
      html_rendering: htmlRenderingType,
      currentStep: number
    ) => {
      let newOptionTable: OptionTableEntry[] = [...optionTable];

      if (typeof newOptionTable[currentStep] === 'undefined') {
        newOptionTable.push({ [key]: option, render: html_rendering });
      } else {
        newOptionTable[currentStep] = {
          [key]: option,
          render: html_rendering,
        };
        newOptionTable = newOptionTable.slice(0, currentStep + 1);
      }

      setOptionTable(newOptionTable);
    },
    [optionTable]
  );

  useEffect(() => {
    renderOption(
      props.option.values,
      props.option.name,
      props.option.html_rendering,
      0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOption = (
    option: OptionRenderingValuesModel,
    currentStep: number
  ) => {
    if (option.child !== null) {
      renderOption(
        option.child.values,
        option.child.name,
        option.child.html_rendering,
        currentStep
      );
    }
  };

  const getOptionDesc = (desc: string, currentStep: number) => {
    if (typeof optionArray[currentStep] === 'undefined') {
      optionArray.push(desc);
    } else {
      optionArray[currentStep] = desc;
      optionArray = optionArray.slice(0, currentStep + 1);
    }
  };

  const getRadioValue = (
    option: string,
    currentStep: number,
    event: string
  ) => {
    let newRadioValue = [...radioValue];
    newRadioValue[currentStep] = { [option]: event };

    if (typeof newRadioValue[currentStep] !== 'undefined') {
      newRadioValue = newRadioValue.slice(0, currentStep + 1);
    }

    setRadioValue(newRadioValue);
  };

  return (
    <>
      {optionTable.map((currentOptions, currentStep: number) =>
        Object.entries(currentOptions).map(([key, value]) => {
          if (key !== 'render' && Array.isArray(value)) {
            if (value.some((data) => data.formule === null)) {
              return (
                <div key={currentStep} className="option-container">
                  {(currentOptions.render === 'auto' ||
                    currentOptions.render === 'radio') && (
                    <RadioRendering
                      option={key}
                      currentStep={currentStep}
                      radioValue={radioValue}
                      values={value}
                      optionArray={optionArray}
                      getRadioValue={(value, step, event) =>
                        getRadioValue(value, step, event)
                      }
                      getOption={(value, step) => getOption(value, step)}
                      getOptionDesc={(value, step) =>
                        getOptionDesc(value, step)
                      }
                    />
                  )}

                  {currentOptions.render === 'select' && (
                    <SelectRendering
                      option={key}
                      currentStep={currentStep}
                      radioValue={radioValue}
                      values={value}
                      getRadioValue={(value, step, event) =>
                        getRadioValue(value, step, event)
                      }
                      getOption={(value, step) => getOption(value, step)}
                      getOptionDesc={(value, step) =>
                        getOptionDesc(value, step)
                      }
                    />
                  )}

                  {currentOptions.render === 'button' && (
                    <ButtonRendering
                      option={key}
                      currentStep={currentStep}
                      radioValue={radioValue}
                      values={value}
                      optionArray={optionArray}
                      getRadioValue={(value, step, event) =>
                        getRadioValue(value, step, event)
                      }
                      getOption={(value, step) => getOption(value, step)}
                      getOptionDesc={(value, step) =>
                        getOptionDesc(value, step)
                      }
                    />
                  )}
                </div>
              );
            } else {
              return (
                <OptionsList
                  key={currentStep}
                  list={value}
                  loading={
                    props.sessionOptionsList
                      ? props.sessionOptionsList.loading
                      : false
                  }
                  loadedSessionOptions={props.loadedSessionOptions}
                  session={props.session}
                  product={props.product}
                  selectedSessions={props.selectedSessions}
                  isDeletable={props.isDeletable}
                  handleChangeQtySession={props.handleChangeQtySession}
                  handleChangeQtyOption={props.handleChangeQtyOption}
                  selectedOptions={props.selectedOptions}
                  totalAllQty={props.totalAllQty}
                  exchangeQty={props.exchangeQty}
                />
              );
            }
          }
        })
      )}
    </>
  );
};

export default TicketOptions;
