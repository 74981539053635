import React from 'react';
import SessionFiltersDropdown from './SessionFiltersDropdown';
import { sessionFilters } from '../../Constants/Constants';
import './Sessions.css';

export type filterType = keyof typeof sessionFilters;
interface SessionFiltersProps {
  filters: { [key in filterType]: filterType[] };
  onHandleFilter: (event: string[], key: string) => void;
}

const SessionFilters = (props: SessionFiltersProps) => (
  <div className={'session-filter-container'}>
    {Object.keys(props.filters).map((filter: filterType, index: number) => {
      if (props.filters[filter].length === 0) {
        return null;
      } else {
        Object.keys(sessionFilters).map((currentFilter: any) => {
          if (filter === sessionFilters[currentFilter].key) {
            if (props.filters[filter].length > 0) {
              return (
                <SessionFiltersDropdown
                  key={index}
                  placeholder={sessionFilters[currentFilter].label}
                  filter={props.filters[filter]}
                  type={filter}
                  onHandleFilter={props.onHandleFilter}
                />
              );
            }
          }
        });
        return null;
      }
    })}
  </div>
);

export default SessionFilters;
