import React, { useCallback, useEffect, useRef, useState } from 'react';
import ModalEmisys from './ModalEmisys';
import { validateFormMessages } from '../../services/validateFormMessage';
import { Form } from 'antd';
import EmisysButton from '../EmisysButton/EmisysButton';
import Translator from '../../services/translator';
import { EmailType, TextType } from '../Form/FormTypesPreview/FormTypesPreview';
import QuestionModel from '../../Models/Form/QuestionModel';
import { useDispatch, useSelector } from 'react-redux';
import { storeType } from '../../index';
import { checkReduxResponse } from '../../services/httpService';
import { showMessageError } from '../Notification/Notification';
import SuccessErrorIcon from '../SuccessErrorIcon/SuccessErrorIcon';
import BarcodeModel from '../../Models/BarcodeModel';
import {
  clearBarcodes,
  forwardBarcodeRequest,
} from '../../redux/actions/barcodes.actions';
import './Modal.css';

interface ModalTransferProps {
  isOpen: boolean;
  handleClose: () => void;
  barcode: BarcodeModel;
  refreshList: () => void;
}

const ModalTransfer = (props: ModalTransferProps) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  let timeout = useRef(null);
  const [loading, setLoading] = useState(false);
  const [connectedMail, setConnectedMail] = useState('');
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const loggedUser = useSelector((state: storeType) => state.users.loggedUser);
  const forwardBarcode = useSelector(
    (state: storeType) => state.barcodes.forwardBarcode
  );

  const handleFinish = (values: any) => {
    setLoading(false);
    dispatch(
      forwardBarcodeRequest(values, {
        id: props.barcode.id,
        signature: props.barcode.signature,
      })
    );
  };

  const closeAfterSuccess = useCallback(() => {
    props.handleClose();
    clearTimeout(timeout.current);
  }, [props]);

  useEffect(() => {
    if (forwardBarcode) {
      setLoading(forwardBarcode.loading);
      if (checkReduxResponse(forwardBarcode, 'barcode')) {
        setSuccessMsg(forwardBarcode.barcode.message);
        props.refreshList();

        timeout.current = setTimeout(() => {
          closeAfterSuccess();
        }, 7000);
      }

      if (forwardBarcode.error) {
        setErrorMsg(forwardBarcode.error.message);
      }
      dispatch(clearBarcodes());
    }
  }, [dispatch, forwardBarcode, closeAfterSuccess, props]);

  useEffect(() => {
    if (checkReduxResponse(loggedUser, 'user')) {
      setConnectedMail(loggedUser.user.email);
    }
  }, [loggedUser]);

  const handleChangeMail = (value: string) => {
    setEmail(value);
    if (connectedMail === value) {
      setErrorMsg(Translator.trans('order.transfer.same.email'));
    } else {
      if (errorMsg) {
        setErrorMsg('');
      }
    }
  };

  return (
    <ModalEmisys
      closable={false}
      open={props.isOpen}
      onCancel={props.handleClose}
      footer={null}
      width={'small'}
      centered
    >
      <div className={'simple-modal'}>
        {successMsg === '' ? (
          <>
            <h2>{Translator.trans('order.transfer.modal.title')}</h2>

            <Form
              ref={formRef}
              layout={'vertical'}
              className={'validate-container-form'}
              onFinish={handleFinish}
              validateMessages={validateFormMessages}
            >
              <TextType
                uniqueId={'firstName'}
                question={
                  new QuestionModel({
                    label: Translator.trans('user.property.firstName'),
                  })
                }
              />
              <TextType
                uniqueId={'lastName'}
                question={
                  new QuestionModel({
                    label: Translator.trans('user.property.lastName'),
                  })
                }
              />

              <EmailType
                uniqueId={'email'}
                question={
                  new QuestionModel({
                    label: Translator.trans('user.property.email'),
                    required: true,
                  })
                }
                onChange={handleChangeMail}
              />

              {errorMsg &&
                showMessageError('', errorMsg, connectedMail !== email)}

              <Form.Item>
                <EmisysButton
                  loading={loading}
                  htmlType="submit"
                  disabled={connectedMail === email || !email}
                >
                  {Translator.trans('word.action.transfer')}
                </EmisysButton>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <h2>{successMsg}</h2>

            <SuccessErrorIcon success />

            <EmisysButton onClick={closeAfterSuccess}>
              {Translator.trans('word.action.close')}
            </EmisysButton>
          </>
        )}
      </div>
    </ModalEmisys>
  );
};

export default ModalTransfer;
