import { formTypeConstant } from '../../Constants/Constants';
import { shopConfig } from '../../Globals/ShopConfig';

type questionImageType = { image: string };
type questionValueType = string | boolean | questionImageType | null;

export default class QuestionModel {
  attributes: any;
  choices: any;
  help: string | null;
  id: string | null;
  label: string;
  maxLength: number | null;
  multiple: boolean;
  placeholder: string | null;
  required: boolean;
  title: boolean;
  titleDepth: number;
  titleLevel: 'title' | 'subtitle' | 'info' | '';
  type: keyof typeof formTypeConstant | '';
  value: questionValueType;

  constructor(data: any) {
    this.attributes = data.attributes;
    this.choices = data.choices;
    this.help = data.help;
    this.id = data.id;
    this.label = data.label;
    this.maxLength = data.maxLength;
    this.multiple = data.multiple;
    this.placeholder = data.placeholder;
    this.required = data.required;
    this.title = data.title;
    this.titleDepth = data.titleDepth;
    this.titleLevel = data.titleLevel;
    this.type = data.type;
    this.value = this.initValue(data.value);
  }

  private initValue = (value: string | boolean | null): questionValueType => {
    let newValue: questionValueType = value;

    if (this.type === formTypeConstant.CountryType) {
      newValue = value !== null ? value : shopConfig.locale.countryCode;
    } else if (
      this.type === formTypeConstant.UserPictureType &&
      typeof value === 'string'
    ) {
      newValue = { image: value };
    } else if (this.type === formTypeConstant.CheckboxType) {
      newValue = value === '1';
    } else {
      newValue = value !== null ? value : '';
    }

    return newValue;
  };
}
