import { paymentConstants } from '../constants/payment.constants';
import PaymentResultModel from '../../Models/PaymentResultModel';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  payment: null,
};

const payment = (state = initialState, action: any) => {
  switch (action.type) {
    case paymentConstants.GET_PAYMENT_INFO_REQUEST: {
      return {
        ...state,
        payment: {
          info: null,
          loading: true,
          error: null,
        },
      };
    }

    case paymentConstants.GET_PAYMENT_INFO_SUCCESS: {
      return {
        ...state,
        payment: {
          info: new PaymentResultModel(action.result),
          loading: false,
          error: null,
        },
      };
    }

    case paymentConstants.GET_PAYMENT_INFO_FAILURE: {
      return {
        ...state,
        payment: {
          info: null,
          loading: false,
          error: new ErrorModel(action.error),
        },
      };
    }
    default:
      return state;
  }
};

export default payment;
