import React, { useCallback, useEffect, useMemo, useState } from 'react';
import history from '../../../services/history';
import { pagesConstant } from '../../../Constants/Constants';
import { Link, useLocation } from 'react-router-dom';
import { Col, Result, Row } from 'antd';
import Container from '../../../components/Container/Container';
import EmisysButton from '../../../components/EmisysButton/EmisysButton';
import Translator from '../../../services/translator';
import '../index.css';

type forwardAssignStatus = 'success' | 'canceled' | 'closed' | 'not_found' | '';

const ForwardAssign = () => {
  const location = useLocation();
  const forwardAssignStatusArray: forwardAssignStatus[] = useMemo(
    () => ['success', 'canceled', 'closed', 'not_found'],
    []
  );
  const [currentStatus, setCurrentStatus] = useState<forwardAssignStatus>('');
  const [errorMessage, setErrorMessage] = useState('');

  const getUrlSearch = useCallback(() => {
    const params = Object.fromEntries(new URLSearchParams(location.search));
    if (
      Object.prototype.hasOwnProperty.call(params, 'id') &&
      Object.prototype.hasOwnProperty.call(params, 'signature') &&
      Object.prototype.hasOwnProperty.call(params, 'status')
    ) {
      if (
        forwardAssignStatusArray.includes(params.status as forwardAssignStatus)
      ) {
        setCurrentStatus(params.status as forwardAssignStatus);
        if (params.status === 'canceled') {
          setErrorMessage(Translator.trans('barcode.forward.error.canceled'));
        }
        if (params.status === 'closed' || params.status === 'not_found') {
          setErrorMessage(Translator.trans('barcode.forward.error'));
        }
      } else {
        history.navigate(pagesConstant.tickets.home);
      }
    } else {
      history.navigate(pagesConstant.tickets.home);
    }
  }, [forwardAssignStatusArray, location.search]);

  useEffect(() => {
    getUrlSearch();
  }, [getUrlSearch]);

  return (
    <Row gutter={16}>
      <Col className="gutter-row" xs={24} sm={24}>
        <Container className={'user-assign-ticket-container'}>
          <div>
            {currentStatus !== '' && (
              <Result
                status={errorMessage ? 'error' : 'success'}
                title={
                  errorMessage
                    ? errorMessage
                    : Translator.trans('barcode.forward.success')
                }
                subTitle={
                  !errorMessage &&
                  Translator.trans('barcode.forward.success.info')
                }
                extra={[
                  <div key={'home'} className={'user-assign-buttons'}>
                    <Link to={pagesConstant.tickets.home}>
                      <EmisysButton isOutlined>
                        {Translator.trans('paymentPage.buttons.back')}
                      </EmisysButton>
                    </Link>
                    {errorMessage === '' && (
                      <Link to={pagesConstant.account.tickets}>
                        <EmisysButton>
                          {Translator.trans('barcode.forward.tickets')}
                        </EmisysButton>
                      </Link>
                    )}
                  </div>,
                ]}
              />
            )}
          </div>
        </Container>
      </Col>
    </Row>
  );
};

export default ForwardAssign;
