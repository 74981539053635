import React from 'react';
import { Select, Tag } from 'antd';
import { filterType } from './SessionFilters';
import './Sessions.css';

const TagRender = (props) => {
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className={'filter-tag'}
      onMouseDown={onPreventMouseDown}
      closable={props.closable}
      onClose={props.onClose}
    >
      {props.label}
    </Tag>
  );
};

interface SessionFiltersDropdownProps {
  placeholder: string;
  filter: filterType[];
  type: filterType;
  onHandleFilter: (event: string[], key: string) => void;
}

const SessionFiltersDropdown = (props: SessionFiltersDropdownProps) => (
  <Select
    mode={'multiple'}
    maxTagCount={'responsive'}
    placeholder={props.placeholder}
    className={'filter-session'}
    showArrow
    allowClear
    tagRender={(props) => <TagRender {...props} />}
    onChange={(event) => props.onHandleFilter(event, props.type)}
  >
    {props.filter.map((item, index) => (
      <Select.Option key={index} value={item}>
        {item}
      </Select.Option>
    ))}
  </Select>
);

export default SessionFiltersDropdown;
