import { datesConstants } from '../constants/dates.constants';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  datesList: null,
};

const dates = (state = initialState, action: any) => {
  switch (action.type) {
    case datesConstants.GET_DATES_LIST_REQUEST: {
      return {
        ...state,
        datesList: { dates: [], error: null, loading: true },
      };
    }

    case datesConstants.GET_DATES_LIST_SUCCESS: {
      return {
        ...state,
        datesList: { dates: action.dates, error: null, loading: false },
      };
    }

    case datesConstants.GET_DATES_LIST_FAILURE: {
      return {
        ...state,
        datesList: {
          dates: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default dates;
