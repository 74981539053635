import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductTypeConstant } from '../../Constants/Constants';

export const productIcon = (type: string) => {
  switch (type) {
    case ProductTypeConstant.ticket:
      return <FontAwesomeIcon icon={'ticket'} />;
    case ProductTypeConstant.pack:
      return <FontAwesomeIcon icon={'cubes'} />;
    case ProductTypeConstant.topup:
      return <FontAwesomeIcon icon={'money-bill'} />;
    case ProductTypeConstant.accommodation:
      return <FontAwesomeIcon icon={'bed'} />;
    case ProductTypeConstant.transportation:
      return <FontAwesomeIcon icon={'car'} />;
    case ProductTypeConstant.drink:
      return <FontAwesomeIcon icon={'glass-water'} />;
    case ProductTypeConstant.food:
      return <FontAwesomeIcon icon={'cutlery'} />;
    case ProductTypeConstant.extra:
      return <FontAwesomeIcon icon={'square-plus'} />;
    case ProductTypeConstant.shop:
      return <FontAwesomeIcon icon={'shopping-cart'} />;
    case ProductTypeConstant.discount:
      return <FontAwesomeIcon icon={'gift'} />;
    default:
      return <FontAwesomeIcon icon={'circle-dot'} />;
  }
};
