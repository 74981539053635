import SessionGroupModel from './SessionGroupModel';

export default class SessionGroupTreeModel {
  numGroups: number[];
  numSchedules: number;
  lowestLevel: SessionGroupModel[] = [];

  constructor(numGroups: number[], numSchedules: number) {
    this.numGroups = numGroups;
    this.numSchedules = numSchedules;
  }

  buildGroupTree(): SessionGroupModel[] {
    return this.buildGroupTreeRecursive(0);
  }

  buildGroupTreeRecursive(level: number): SessionGroupModel[] {
    const number = Math.min(
      this.numGroups[level],
      this.numSchedules - this.lowestLevel.length
    );
    const groups = new Array(number)
      .fill(undefined)
      .map(() => new SessionGroupModel());
    if (level + 1 < this.numGroups.length) {
      groups.forEach(
        (group) => (group.groups = this.buildGroupTreeRecursive(level + 1))
      );
    } else {
      this.lowestLevel = this.lowestLevel.concat(groups);
    }
    return groups;
  }

  getLowestGroups(): SessionGroupModel[] {
    return this.lowestLevel;
  }
}
