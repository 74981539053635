import Translator from '../services/translator';

export default class ErrorModel {
  status: string | number;
  code: string;
  error: string;
  message: string;
  errors: any;

  constructor(data: any) {
    this.status = data.status;
    this.code = data.body.code;
    this.error = data.body.error;
    this.message =
      data.body.message ?? Translator.trans('error.internal_error.title');
    this.errors = data.body.errors ?? data.body.formErrors ?? null;
  }
}
