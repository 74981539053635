import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { barcodesConstants } from '../constants/barcodes.constants';
import {
  getExchangeBarcodesListSuccess,
  getExchangeBarcodesListFailure,
  postExchangeBarcodeSuccess,
  postExchangeBarcodeFailure,
  forwardBarcodeSuccess,
  forwardBarcodeFailure,
  postBarcodeAttendeeFailure,
  getBarcodeAttendeeSuccess,
  getBarcodeAttendeeFailure,
  postBarcodeAttendeeSuccess,
  getBarcodesListSuccess,
  getBarcodesListFailure,
} from '../actions/barcodes.actions';
import { getUrl, get, post } from '../../services/httpService';
import { shopConfig } from '../../Globals/ShopConfig';

export async function getUserBarcodesListFetch(arg: any) {
  const url = shopConfig.router.userBarcodeListUrl;
  return await get(getUrl(url, arg.params));
}

export async function getExchangeBarcodesListFetch(arg: any) {
  const url = shopConfig.router.barcodeExchangeListUrl;
  return await get(getUrl(url, arg.params));
}

export async function postExchangeBarcodeFetch(arg: any) {
  const url = shopConfig.router.barcodeExchangeSaveUrl;
  return await post(getUrl(url, arg.params), arg.barcode);
}

export async function forwardBarcodeFetch(arg: any) {
  const url = shopConfig.router.barcodeForwardSendUrl;
  return await post(getUrl(url, arg.params), arg.values);
}

export async function getBarcodeAttendeeFetch(arg: any) {
  const url = shopConfig.router.barcodeAttendeeFormUrl;
  return await get(getUrl(url, arg.barcode));
}

export async function postBarcodeAttendeeFetch(arg: any) {
  const url = shopConfig.router.barcodeAttendeeFormSaveUrl;
  return await post(getUrl(url, arg.params), arg.payload, {
    formData: true,
  });
}

export function* fetchGetUserBarcodesList(action: any) {
  try {
    const barcodesList = yield call(getUserBarcodesListFetch, {
      params: action.params,
    });
    yield put(getBarcodesListSuccess(barcodesList));
  } catch (error) {
    yield put(getBarcodesListFailure(error));
  }
}

export function* fetchGetExchangeBarcodesList(action: any) {
  try {
    const productsList = yield call(getExchangeBarcodesListFetch, {
      params: action.params,
    });
    yield put(getExchangeBarcodesListSuccess(productsList));
  } catch (error) {
    yield put(getExchangeBarcodesListFailure(error));
  }
}

export function* fetchPostExchangeBarcode(action: any) {
  try {
    const newBarcode = yield call(postExchangeBarcodeFetch, {
      barcode: action.barcode,
      params: action.params,
    });
    yield put(postExchangeBarcodeSuccess(newBarcode));
  } catch (error) {
    yield put(postExchangeBarcodeFailure(error));
  }
}

export function* fetchPostForwardBarcode(action: any) {
  try {
    const barcode = yield call(forwardBarcodeFetch, {
      values: action.values,
      params: action.params,
    });
    yield put(forwardBarcodeSuccess(barcode));
  } catch (error) {
    yield put(forwardBarcodeFailure(error));
  }
}

export function* fetchGetBarcodeAttendee(action: any) {
  try {
    const attendee = yield call(getBarcodeAttendeeFetch, {
      barcode: action.barcode,
    });
    yield put(getBarcodeAttendeeSuccess(attendee));
  } catch (error) {
    yield put(getBarcodeAttendeeFailure(error));
  }
}

export function* fetchPostBarcodeAttendee(action: any) {
  try {
    const newAttendee = yield call(postBarcodeAttendeeFetch, {
      payload: action.payload,
      params: action.params,
    });
    yield put(postBarcodeAttendeeSuccess(newAttendee));
  } catch (error) {
    yield put(postBarcodeAttendeeFailure(error));
  }
}

export function* getUserBarcodesList() {
  yield takeLatest(
    barcodesConstants.GET_BARCODES_LIST_REQUEST,
    fetchGetUserBarcodesList
  );
}

export function* getExchangeBarcodesList() {
  yield takeLatest(
    barcodesConstants.GET_EXCHANGE_BARCODES_REQUEST,
    fetchGetExchangeBarcodesList
  );
}

export function* postExchangeBarcode() {
  yield takeLatest(
    barcodesConstants.POST_EXCHANGE_BARCODE_REQUEST,
    fetchPostExchangeBarcode
  );
}

export function* postForwardBarcode() {
  yield takeLatest(
    barcodesConstants.POST_FORWARD_BARCODE_REQUEST,
    fetchPostForwardBarcode
  );
}

export function* getBarcodeAttendee() {
  yield takeLatest(
    barcodesConstants.GET_BARCODE_ATTENDEE_REQUEST,
    fetchGetBarcodeAttendee
  );
}

export function* postBarcodeAttendee() {
  yield takeLatest(
    barcodesConstants.POST_BARCODE_ATTENDEE_REQUEST,
    fetchPostBarcodeAttendee
  );
}

export default function* barcodesSaga() {
  yield all([
    fork(getUserBarcodesList),
    fork(getExchangeBarcodesList),
    fork(postExchangeBarcode),
    fork(postForwardBarcode),
    fork(getBarcodeAttendee),
    fork(postBarcodeAttendee),
  ]);
}
