import { paymentMethodConstants } from '../constants/paymentMethod.constants';
import PaymentMethodModel from '../../Models/OrderOverview/PaymentMethodModel';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  paymentMethodList: null,
};

let listPaymentMethod = null;
const paymentMethod = (state = initialState, action: any) => {
  switch (action.type) {
    case paymentMethodConstants.GET_PAYMENTMETHOD_REQUEST: {
      return {
        ...state,
        paymentMethodList: {
          paymentMethod: listPaymentMethod,
          error: null,
          loading: true,
        },
      };
    }

    case paymentMethodConstants.GET_PAYMENTMETHOD_SUCCESS: {
      listPaymentMethod = PaymentMethodModel.setPaymentMethodModel(
        action.paymentMethod
      );
      return {
        ...state,
        paymentMethodList: {
          paymentMethod: listPaymentMethod,
          error: null,
          loading: false,
        },
      };
    }

    case paymentMethodConstants.GET_PAYMENTMETHOD_FAILURE: {
      return {
        ...state,
        paymentMethodList: {
          paymentMethod: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default paymentMethod;
