import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLoggedUserRequest } from '../../redux/actions/users.actions';
import {
  getRefreshTokenStorage,
  getTokenStorage,
  removeTokenToStorage,
} from '../../services/handleTokens';
import { openNotificationError } from '../Notification/Notification';
import TopMenu from './TopMenu/TopMenu';
import { dispatchLogin } from '../../services/tracking';
import { shopConfig } from '../../Globals/ShopConfig';
import MediaQuery from 'react-responsive';
import Translator from '../../services/translator';
import audienceLogo from '../../assets/images/audience.png';
import { pagesConstant } from '../../Constants/Constants';
import { storeType } from '../../index';
import { ShopOutlined } from '@ant-design/icons';
import './Header.css';

export type userProfileType = {
  fullName: string;
  picture: string | null;
  reseller: boolean;
} | null;

const Header = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<userProfileType>(null);
  const token = getTokenStorage();
  const refreshToken = getRefreshTokenStorage();

  const loggedUser = useSelector((state: storeType) => state.users.loggedUser);

  const getLoggedUser = useCallback(() => {
    dispatch(getLoggedUserRequest({ project_id: shopConfig.project.id }));
  }, [dispatch]);

  useEffect(() => {
    if (token && refreshToken) {
      getLoggedUser();
    }
  }, [getLoggedUser, refreshToken, token]);

  useEffect(() => {
    if (loggedUser) {
      setIsLoading(loggedUser.loading);
      if (!loggedUser.loading && !loggedUser.error) {
        if (loggedUser.user) {
          dispatchLogin(loggedUser.user.id);
          setUserProfile({
            fullName: loggedUser.user.fullName,
            picture: loggedUser.user.avatar,
            reseller: loggedUser.user.isReseller,
          });
        } else {
          setUserProfile(null);
        }
      }

      if (loggedUser.error) {
        if (loggedUser.error.status !== 401) {
          openNotificationError(
            Translator.trans('error.internal_error.title'),
            loggedUser.error.message
          );
        }
        removeTokenToStorage();
      }
    }
  }, [loggedUser]);

  return (
    <header className="main-header">
      <nav className="header-shop-container">
        <Link to={pagesConstant.index} className={'logo-event-container'}>
          <img
            className="logo-event"
            src={shopConfig.branding.logoPictureUrl ?? audienceLogo}
            alt="logo-shop"
          />
        </Link>

        <MediaQuery minWidth={769}>
          <TopMenu
            isLoading={isLoading}
            userProfile={userProfile}
            isMobile={false}
          />
        </MediaQuery>

        <MediaQuery maxWidth={768}>
          <TopMenu
            isLoading={isLoading}
            userProfile={userProfile}
            isMobile={true}
          />
        </MediaQuery>
      </nav>
      {userProfile?.reseller && (
        <div className="reseller-banner">
          <span>{Translator.trans('user.is.reseller')}</span>
          <ShopOutlined />
        </div>
      )}
    </header>
  );
};

export default Header;
