import '../../Models/SessionModel';
import SessionGroupTreeModel from './SessionGroupTreeModel';
import { formatTime } from '../../services/formatDate';
import SessionModel from '../SessionModel';

type SchedulesType = { [key: string]: SessionModel[] }[];

export default class SessionGroupModel {
  minDate: Date = new Date();
  maxDate: Date = new Date();
  label: string = '';
  qtyRemaining: number | null = null; // Null if no limit on remaining quantity
  groups: SessionGroupModel[] = [];
  page: number | null = null;

  /**
   * Compute the depth of the selection tree and the number of buttons to display at each level.
   *
   * The algorithm is designed to never display more than maxButtons per group. But there can be less buttons to evenly
   * share sessions in each group.
   * A group must never contain only one button.
   *
   * The grouping is purely mathematical. It doesn't try to make sense of the actual time to suggest grouping
   * with round times (i.e. 10:00-12:00). As a result, it can show time range such as 14:32-16:16.
   */

  static buildFromSessions(
    maxButtons: number,
    schedules: SchedulesType
  ): SessionGroupModel[] {
    const numPages = schedules.length;
    if (numPages <= 1) {
      // no need for grouping
      return [];
    }
    /*
     * Depth of a tree fanning out up to the number of sessions with at most maxButtons at
     * each node.
     */
    const depth = Math.ceil(Math.log(numPages) / Math.log(maxButtons));
    const numGroups = this.bestNumGroups(numPages, depth);
    return this.buildGroupTree(numGroups, schedules);
  }

  static bestNumGroups(numSchedules: number, depth: number): number[] {
    if (depth <= 1) {
      return [numSchedules];
    }
    /*
     * Find the edge length of a square big enough to hold at least the requested number of schedules.
     * At depth two, it is the size of a square whose area is numSchedules, i.e. square root of numSchedules
     * At depth three, it is the size of a cube whose volume is numSchedules, i.e. cubic root of numSchedules.
     * At depth four, it is the size of a tesseract, i.e. 4th root of numSchedules.
     * Generalised computation for root(X, N) is pow(X, 1/N).
     */
    const minEdgeSize = Math.ceil(Math.pow(numSchedules, 1 / depth));
    /*
     * A square/cube/tesseract would have a lot of holes. For instance, the next cube above 28 is 64 with a size of 4*4*4.
     * It means more than half the slots are empty (as only 28/64 would contain a session).
     * To avoid wasting so much space (that is, showing empty buttons), we evaluate the remaining "volume" and iterate
     * over that smaller volume.
     * For instance, 27 sessions require 3 levels in the selection tree. Each level has 3 buttons because 3*3*3=27.
     * 28 sessions is just one more. It still requires 3 levels (depth is still 3). But tne next cube capable of holding
     * it has size 4. It can hold 4*4*4=64 buttons with only 28 leading to a session.
     * To reduce the number of buttons, once we have minEdgeSize=4, we see there are 28/4=7 slots to fill over the next
     * two levels (that is, 28 sessions divided by the size of the cube that can hold 28). As there are only 7 buttons
     * to store, the next square above 7 is 9=3*3.
     * In the end, the number of buttons at each level ends up as [4, 3, 3]. The total volume is 4*3*3=36 which is
     * much less than 64 and still evenly share the buttons in each group.
     */
    const leftToSplit = Math.ceil(numSchedules / minEdgeSize);

    return [minEdgeSize, ...this.bestNumGroups(leftToSplit, depth - 1)];
  }

  static buildGroupTree(
    numGroups: number[],
    schedules: SchedulesType
  ): SessionGroupModel[] {
    const tree = new SessionGroupTreeModel(numGroups, schedules.length);
    const groups = tree.buildGroupTree();
    const lowestGroups = tree.getLowestGroups();
    this.splitSchedulesAmongGroups(schedules, lowestGroups);
    this.initGroupsRecursive(groups, schedules);

    return groups;
  }

  static splitSchedulesAmongGroups(
    schedules: SchedulesType,
    groups: SessionGroupModel[]
  ) {
    schedules.forEach((page, index) => {
      groups[index].page = index;
    });
  }

  static initGroupsRecursive(
    groups: SessionGroupModel[],
    sessionPages: SchedulesType
  ) {
    if (groups.length === 0) {
      return;
    }
    groups.forEach((group) => {
      this.initGroupsRecursive(group.groups, sessionPages);
      group.setGroupDates(sessionPages);
    });
  }

  setGroupDates(sessionPages: SchedulesType) {
    let minDate: Date;
    let maxDate: Date;
    let qtyRemaining: number | null = 0;
    if (this.groups.length > 0 || this.page === null) {
      this.groups.forEach((group) => {
        if (minDate === undefined || minDate > group.minDate) {
          minDate = group.minDate;
        }
        if (maxDate === undefined || maxDate < group.maxDate) {
          maxDate = group.maxDate;
        }
        if (group.qtyRemaining === null) {
          qtyRemaining = null;
        } else if (qtyRemaining !== null) {
          qtyRemaining += group.qtyRemaining;
        }
      });
    } else {
      const currentSessionPage = sessionPages[this.page];
      Object.keys(currentSessionPage).forEach((key) => {
        const page = currentSessionPage[key];
        page.forEach((session) => {
          const dateStart = session.dateStart;
          if (minDate === undefined || minDate > dateStart) {
            minDate = dateStart;
          }
          if (maxDate === undefined || maxDate < dateStart) {
            maxDate = dateStart;
          }
          if (session.qtyRemaining === null) {
            qtyRemaining = null;
          } else if (qtyRemaining !== null) {
            qtyRemaining += session.qtyRemaining;
          }
        });
      });
    }
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.label = formatTime(minDate) + ' - ' + formatTime(maxDate);
    this.qtyRemaining = qtyRemaining;
  }

  hasGroups() {
    return this.groups.length > 0;
  }
}
