import React, { useCallback, useEffect, useRef, useState } from 'react';
import ModalEmisys from './ModalEmisys';
import BarcodeModel from '../../Models/BarcodeModel';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearBarcodes,
  getBarcodeAttendeeRequest,
  postBarcodeAttendeeRequest,
} from '../../redux/actions/barcodes.actions';
import { storeType } from '../../index';
import { checkReduxResponse } from '../../services/httpService';
import BarcodeAttendeeModel from '../../Models/BarcodeAttendeeModel';
import { Type } from '../Form/FormType';
import { Form, Result, Skeleton } from 'antd';
import QuestionModel from '../../Models/Form/QuestionModel';
import { validateFormMessages } from '../../services/validateFormMessage';
import EmisysButton from '../EmisysButton/EmisysButton';
import Translator from '../../services/translator';
import './Modal.css';
import { openNotificationError } from '../Notification/Notification';
import payloadToMultipartForm from '../../services/payloadToMultipartForm';
import SuccessErrorIcon from '../SuccessErrorIcon/SuccessErrorIcon';
interface ModalTransferProps {
  isOpen: boolean;
  handleClose: () => void;
  barcode: BarcodeModel;
  refreshList: () => void;
}

const ModalUpdateUser = (props: ModalTransferProps) => {
  const dispatch = useDispatch();
  const barcodeRef: any = useRef();
  let timeout = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [currentAttendee, setCurrentAttendee] =
    useState<BarcodeAttendeeModel | null>(null);
  const [successMsg, setSuccessMsg] = useState('');

  const barcodeAttendee = useSelector(
    (state: storeType) => state.barcodes.barcodeAttendee
  );
  const newBarcodeAttendee = useSelector(
    (state: storeType) => state.barcodes.newBarcodeAttendee
  );

  useEffect(() => {
    dispatch(
      getBarcodeAttendeeRequest({
        id: props.barcode.id,
        signature: props.barcode.signature,
      })
    );
  }, [dispatch, props.barcode.id, props.barcode.signature]);

  const initiateForm = (questions: QuestionModel[]) => {
    const barcodeInfo = {};

    questions.forEach((question) => {
      if (
        question.titleLevel !== 'title' &&
        question.titleLevel !== 'subtitle'
      ) {
        barcodeInfo[question.id] = question.value;
      }
    });

    setFormValues(barcodeInfo);
  };

  useEffect(() => {
    if (barcodeAttendee) {
      setIsLoading(barcodeAttendee.loading);
      if (checkReduxResponse(barcodeAttendee, 'attendee')) {
        setCurrentAttendee(barcodeAttendee.attendee);
        initiateForm(barcodeAttendee.attendee.questions);
      }

      if (barcodeAttendee.error) {
        setErrorMsg(barcodeAttendee.error.message);
      }

      dispatch(clearBarcodes());
    }
  }, [dispatch, barcodeAttendee]);

  const handleDisplayErrors = useCallback((errors) => {
    let setErrors = [];

    errors.forEach((error) => {
      let matches = (error.fieldName.match(/\[.+?]/g) || []).map((str) =>
        str.slice(1, -1)
      );

      if (matches.length > 0) {
        setErrors.push({
          name: matches.join('#'),
          errors: [error.message],
        });
      }
    });

    if (setErrors.length > 0) {
      barcodeRef?.current?.setFields(setErrors);
      barcodeRef?.current?.scrollToField(setErrors[0].name);
    }
  }, []);

  const closeAfterSuccess = useCallback(() => {
    props.handleClose();
    clearTimeout(timeout.current);
  }, [props]);

  useEffect(() => {
    if (newBarcodeAttendee) {
      setIsLoadingSend(newBarcodeAttendee.loading);
      if (checkReduxResponse(newBarcodeAttendee, 'attendee')) {
        props.refreshList();
        setSuccessMsg(Translator.trans('order.change.success.message'));

        timeout.current = setTimeout(() => {
          closeAfterSuccess();
        }, 7000);
      }

      if (newBarcodeAttendee.error) {
        if (newBarcodeAttendee.error.message) {
          openNotificationError(
            Translator.trans('error.internal_error.title'),
            newBarcodeAttendee.error.message
          );
        }

        if (newBarcodeAttendee.error.errors) {
          handleDisplayErrors(newBarcodeAttendee.error.errors);
        }
      }

      dispatch(clearBarcodes());
    }
  }, [
    dispatch,
    newBarcodeAttendee,
    handleDisplayErrors,
    props,
    closeAfterSuccess,
  ]);

  const handleRemoveErrors = (obj: any) => {
    let errors = [];

    Object.keys(obj).map((key) =>
      errors.push({
        name: key,
        errors: '',
      })
    );

    if (barcodeRef.current) {
      barcodeRef.current.setFields(errors);
    }
  };

  const onFinish = (values: any) => {
    handleRemoveErrors(values);
    const formPayload = payloadToMultipartForm(values);

    dispatch(
      postBarcodeAttendeeRequest(formPayload, {
        id: props.barcode.id,
        signature: props.barcode.signature,
      })
    );
  };

  return (
    <ModalEmisys
      closable={false}
      open={props.isOpen}
      onCancel={props.handleClose}
      footer={null}
      width={'small'}
      centered
    >
      <div className={'simple-modal'}>
        {successMsg === '' ? (
          <Skeleton loading={isLoading} active>
            {errorMsg ? (
              <Result
                status="error"
                title={errorMsg}
                style={{ padding: '0' }}
                extra={[
                  <EmisysButton key={1} onClick={props.handleClose}>
                    {Translator.trans('word.action.back')}
                  </EmisysButton>,
                ]}
              />
            ) : (
              formValues && (
                <Form
                  ref={barcodeRef}
                  name="barcodeForm"
                  onFinish={onFinish}
                  initialValues={formValues}
                  validateMessages={validateFormMessages}
                >
                  {currentAttendee?.questions?.map((question, index) => (
                    <Type
                      key={index}
                      uniqueId={question.id}
                      saleKey={''}
                      question={question}
                      value={formValues[question.id]}
                    />
                  ))}

                  <EmisysButton htmlType="submit" loading={isLoadingSend}>
                    {Translator.trans('word.action.confirm')}
                  </EmisysButton>
                </Form>
              )
            )}
          </Skeleton>
        ) : (
          <>
            <h2>{successMsg}</h2>

            <SuccessErrorIcon success />

            <EmisysButton onClick={closeAfterSuccess}>
              {Translator.trans('word.action.close')}
            </EmisysButton>
          </>
        )}
      </div>
    </ModalEmisys>
  );
};

export default ModalUpdateUser;
