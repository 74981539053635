import React from 'react';
import AppleWallet_US from '../../assets/images/AppleWallets/AppleWallet_US.svg';
import AppleWallet_NL from '../../assets/images/AppleWallets/AppleWallet_NL.svg';
import AppleWallet_FR from '../../assets/images/AppleWallets/AppleWallet_FR.svg';

const WalletApple = ({ locale }: { locale: string }) => {
  switch (locale) {
    case 'nl-NL':
      return <img src={AppleWallet_NL} alt={'Voeg toe aan Apple Wallet'} />;
    case 'fr-FR':
      return <img src={AppleWallet_FR} alt={"Ajouter à l'app Carte Apple"} />;
    case 'en-US':
    default:
      return <img src={AppleWallet_US} alt={'Add to Apple Wallet'} />;
  }
};

export default WalletApple;
