import React from 'react';
import BarcodeModel from '../../../Models/BarcodeModel';
import OrderItemModel from '../../../Models/OrderItemModel';
import SimplePrice from '../../../components/Price/SimplePrice';
import { currencyPrecision } from '../../../Constants/Constants';
import '../index.css';

interface OrderDetailItemInterface {
  item: OrderItemModel;
  barcode?: BarcodeModel;
  getOrderDetail: () => void;
}

const OrderDetailItem = (props: OrderDetailItemInterface) => (
  <div className={'order-detail-item-container'}>
    <div className={'order-detail-item-left'}>
      <h2>{props.item.name}</h2>
    </div>

    <div className={'order-detail-item-right'}>
      <div className={'order-detail-item-price'}>
        <span className={'order-detail-item-unit-price'}>
          <SimplePrice
            price={props.item.unitPriceInclTax.amount}
            currency={props.item.unitPriceInclTax.currency}
          />

          {props.item.quantity > 1 &&
            props.item.quantity !== props.item.barcodes.length && (
              <div className={'order-detail-item-quantity'}>
                &times;{props.item.quantity}
              </div>
            )}
        </span>

        {props.item.quantity > 1 &&
          props.item.quantity !== props.item.barcodes.length &&
          Math.abs(props.item.priceInclTax.amount) >= currencyPrecision && (
            <span className={'order-detail-item-full-price'}>
              {props.item.priceInclTax.formattedPrice}
            </span>
          )}
      </div>
    </div>
  </div>
);

export default OrderDetailItem;
