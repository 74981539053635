import React, { useEffect } from 'react';
import { Modal, ModalProps } from 'antd';
import { shopIsIframe } from '../../Globals/ShopConfig';
import './Modal.css';

interface ModalEmisysProps extends ModalProps {
  width?: 'large' | 'medium' | 'small';
}

const ModalEmisys = (props: ModalEmisysProps) => {
  useEffect(() => {
    if (props.open && shopIsIframe) {
      parent.postMessage({ type: 'emisys_audience_overlap', value: true }, '*');
    }

    return () => {
      if (shopIsIframe) {
        parent.postMessage(
          { type: 'emisys_audience_overlap', value: false },
          '*'
        );
      }
    };
  }, [props.open]);

  let currentWidth: number;
  switch (props.width) {
    case 'large':
      currentWidth = 1440;
      break;
    case 'small':
      currentWidth = 540;
      break;
    case 'medium':
    default:
      currentWidth = 960;
      break;
  }

  return (
    <Modal {...props} width={currentWidth}>
      {props.children}
    </Modal>
  );
};

export default ModalEmisys;
