import React, { useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import Container from '../Container/Container';
import SessionSchedule from '../Sessions/SessionSchedule';
import { formatToFilterLocaleDate } from '../../services/formatDate';
import { getFilterDateStorage } from '../../services/handleStorage';
import { shopConfig } from '../../Globals/ShopConfig';
import SessionModel from '../../Models/SessionModel';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';

type filterDateType = {
  [date: string]: {
    [time: string]: SessionModel[];
  };
};

interface DrawerCalendarSessionInterface {
  showCalender: boolean;
  showSchedule: boolean;
  filterDate: filterDateType;
  onClickDay: (date: string) => void;
  onClickSchedule: (sessions: SessionModel[]) => void;
  showArrow: (show: boolean) => void;
  currentSession: SessionModel;
}

const DrawerCalendarSession = (props: DrawerCalendarSessionInterface) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [enabledDates, setEnabledDates] = useState([]);

  const handleClickDay = useCallback(
    (date: Date) => {
      const keyDate = formatToFilterLocaleDate(date);
      const currentSchedule = props.filterDate[keyDate];
      setCurrentSchedule(currentSchedule);
      setCurrentDate(date);
      props.onClickDay(keyDate);
    },
    [props]
  );

  const handleEnabledDates = useCallback(() => {
    if (props.filterDate && Object.keys(props.filterDate).length > 0) {
      const newEnabledDates = Object.keys(props.filterDate);
      if (newEnabledDates.length !== enabledDates.length) {
        setEnabledDates(newEnabledDates);

        const currentFilterDate = getFilterDateStorage();
        const activeFirstDate = currentFilterDate
          ? currentFilterDate
          : new Date(newEnabledDates[0] + 'T00:00');

        setCurrentDate(activeFirstDate);
        handleClickDay(activeFirstDate);
      }
    }
  }, [enabledDates.length, handleClickDay, props.filterDate]);

  useEffect(() => {
    handleEnabledDates();
  }, [handleEnabledDates]);

  const disableCalenderTile = ({ date }: { date: Date }) => {
    const keyDate = formatToFilterLocaleDate(date);

    const today = new Date();
    const yesterday = new Date(today.getTime());
    yesterday.setDate(today.getDate() - 1);

    const filterDate = getFilterDateStorage();

    if (filterDate) {
      const DayBeforeFilterDate = filterDate.setDate(filterDate.getDate());
      const DayAfterFilterDate = filterDate.setDate(filterDate.getDate() - 1);
      return (
        date < new Date(DayAfterFilterDate) ||
        date > new Date(DayBeforeFilterDate)
      );
    } else {
      return !enabledDates.includes(keyDate) || date < yesterday;
    }
  };

  return (
    <div className="drawer-calendar-container">
      {props.showCalender && (
        <Calendar
          className="drawer-calendar"
          tileClassName="drawer-calendar-tile"
          minDetail="month"
          locale={shopConfig.locale.locale.replace('_', '-')}
          tileDisabled={disableCalenderTile}
          onClickDay={handleClickDay}
          value={currentDate}
        />
      )}
      <Container
        className={`drawer-calendar-schedule 
           ${!props.showSchedule && 'hidden'}
            ${!props.showCalender && 'hiddenCalendar'}
          `}
      >
        {currentSchedule && (
          <SessionSchedule
            currentSchedule={currentSchedule}
            currentDate={currentDate}
            onClickSchedule={props.onClickSchedule}
            showArrow={props.showArrow}
            currentSession={props.currentSession}
          />
        )}
      </Container>
    </div>
  );
};

export default DrawerCalendarSession;
