import { barcodesConstants } from '../constants/barcodes.constants';
import ProductModel from '../../Models/ProductModel';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';
import BarcodeAttendeeModel from '../../Models/BarcodeAttendeeModel';
import BarcodeModel from '../../Models/BarcodeModel';

const initialState: { [stateName: string]: reducerItemType } = {
  userBarcodesList: null,
  exchangeBarcodesList: null,
  newExchangeBarcode: null,
  forwardBarcode: null,
  barcodeAttendee: null,
  newBarcodeAttendee: null,
};

let listUserBarcodes = null;

const barcodes = (state = initialState, action: any) => {
  switch (action.type) {
    case barcodesConstants.GET_BARCODES_LIST_REQUEST: {
      return {
        ...state,
        userBarcodesList: {
          barcodes: listUserBarcodes,
          error: null,
          loading: true,
        },
      };
    }

    case barcodesConstants.GET_BARCODES_LIST_SUCCESS: {
      listUserBarcodes = action.response.map(
        (barcodes: any) => new BarcodeModel(barcodes)
      );
      return {
        ...state,
        userBarcodesList: {
          barcodes: listUserBarcodes,
          error: null,
          loading: false,
        },
      };
    }

    case barcodesConstants.GET_BARCODES_LIST_FAILURE: {
      return {
        ...state,
        userBarcodesList: {
          barcodes: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case barcodesConstants.GET_EXCHANGE_BARCODES_REQUEST: {
      return {
        ...state,
        exchangeBarcodesList: {
          barcodes: null,
          error: null,
          loading: true,
        },
      };
    }

    case barcodesConstants.GET_EXCHANGE_BARCODES_SUCCESS: {
      const list = action.response?.formules
        ? action.response.formules.map(
            (formule: any) => new ProductModel(formule)
          )
        : [];
      return {
        ...state,
        exchangeBarcodesList: {
          barcodes: list,
          error: null,
          loading: false,
        },
      };
    }

    case barcodesConstants.GET_EXCHANGE_BARCODES_FAILURE: {
      return {
        ...state,
        exchangeBarcodesList: {
          barcodes: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case barcodesConstants.POST_EXCHANGE_BARCODE_REQUEST: {
      return {
        ...state,
        newExchangeBarcode: { barcode: null, error: null, loading: true },
      };
    }

    case barcodesConstants.POST_EXCHANGE_BARCODE_SUCCESS: {
      return {
        ...state,
        newExchangeBarcode: {
          barcode: action.response,
          error: null,
          loading: false,
        },
      };
    }

    case barcodesConstants.POST_EXCHANGE_BARCODE_FAILURE: {
      return {
        ...state,
        newExchangeBarcode: {
          barcode: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case barcodesConstants.POST_FORWARD_BARCODE_REQUEST: {
      return {
        ...state,
        forwardBarcode: { barcode: null, error: null, loading: true },
      };
    }

    case barcodesConstants.POST_FORWARD_BARCODE_SUCCESS: {
      return {
        ...state,
        forwardBarcode: {
          barcode: action.response,
          error: null,
          loading: false,
        },
      };
    }

    case barcodesConstants.POST_FORWARD_BARCODE_FAILURE: {
      return {
        ...state,
        forwardBarcode: {
          barcode: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case barcodesConstants.GET_BARCODE_ATTENDEE_REQUEST: {
      return {
        ...state,
        barcodeAttendee: { attendee: null, error: null, loading: true },
      };
    }

    case barcodesConstants.GET_BARCODE_ATTENDEE_SUCCESS: {
      return {
        ...state,
        barcodeAttendee: {
          attendee: new BarcodeAttendeeModel(action.response),
          error: null,
          loading: false,
        },
      };
    }

    case barcodesConstants.GET_BARCODE_ATTENDEE_FAILURE: {
      return {
        ...state,
        barcodeAttendee: {
          attendee: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case barcodesConstants.POST_BARCODE_ATTENDEE_REQUEST: {
      return {
        ...state,
        newBarcodeAttendee: { attendee: null, error: null, loading: true },
      };
    }

    case barcodesConstants.POST_BARCODE_ATTENDEE_SUCCESS: {
      return {
        ...state,
        newBarcodeAttendee: {
          attendee: action.response,
          error: null,
          loading: false,
        },
      };
    }

    case barcodesConstants.POST_BARCODE_ATTENDEE_FAILURE: {
      return {
        ...state,
        newBarcodeAttendee: {
          attendee: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case barcodesConstants.CLEAR_EXCHANGE_BARCODES: {
      return {
        userBarcodesList: null,
        exchangeBarcodesList: null,
        newExchangeBarcode: null,
        forwardBarcode: null,
        barcodeAttendee: null,
        newBarcodeAttendee: null,
      };
    }

    default:
      return state;
  }
};

export default barcodes;
