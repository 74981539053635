import React from 'react';
import ModalEmisys from './ModalEmisys';
import { shopConfig } from '../../Globals/ShopConfig';
import { Language } from '@seatsio/seatsio-types';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import BarcodeModel from '../../Models/BarcodeModel';
import './Modal.css';

interface ModalPlacementProps {
  isOpen: boolean;
  handleClose: () => void;
  barcode: BarcodeModel;
}

const ModalPlacement = (props: ModalPlacementProps) => (
  <ModalEmisys
    closable={false}
    open={props.isOpen}
    onCancel={props.handleClose}
    footer={null}
    width={'small'}
    centered
  >
    <div className={'simple-modal'}>
      <h2>{props.barcode.item.name}</h2>
      <SeatsioSeatingChart
        mode={'spotlight'}
        divId={'seats-ticket-placement'}
        workspaceKey={shopConfig.placementMap.seatsioApiWorkspaceKey}
        event={props.barcode.placementEventKey}
        selectedObjects={[props.barcode.placementUnitLabel]}
        region={shopConfig.placementMap.seatsioApiRegion}
        language={shopConfig.locale.language as Language}
      />
    </div>
  </ModalEmisys>
);

export default ModalPlacement;
