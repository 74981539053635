import React, { Fragment } from 'react';
import SessionListItem from './SessionListItem';
import SessionListOptionItem from './SessionListOptionItem';
import {
  getMidnightDate,
  formatDateToLongString,
} from '../../services/formatDate';
import ProductModel from '../../Models/ProductModel';
import SessionModel from '../../Models/SessionModel';
import OptionModel from '../../Models/OptionModel';
import SessionOptionModel from '../../Models/SessionOptionModel';
import OptionRenderingModel from '../../Models/OptionRenderingModel';
import './Sessions.css';

type loadedSessionOptionsType = {
  sessionId: number;
  productId: number;
  options: SessionOptionModel[];
};

interface SessionListProps extends SessionListChildProps {
  list: { [kay: string]: SessionModel[] };
  unitPrice: number;
  activeDate?: string;
}

export interface SessionListChildProps {
  product: ProductModel;
  option: null | OptionRenderingModel;
  loadedSessionOptions: loadedSessionOptionsType[];
  selectedOptions: OptionModel[];
  handleChangeQtyOption: (
    item: OptionModel,
    quantity: number,
    session: SessionModel
  ) => void;
  handleChangeQtySession: (session: SessionModel, quantity: number) => void;
  sessionOptionsList?: null | {
    error: boolean;
    loading: boolean;
    options: SessionOptionModel[];
  };
  totalAllQty: number;
  exchangeQty?: number;
}

const SessionList = (props: SessionListProps) => (
  <>
    {Object.keys(props.list).map((listItem: string, index: number) => {
      if (listItem !== props.activeDate) {
        return (
          <Fragment key={index}>
            <h3>{formatDateToLongString(getMidnightDate(listItem))}</h3>
            {props.list[listItem].map((session: SessionModel) =>
              !session?.placementEventKey ? (
                props.option ? (
                  <SessionListOptionItem
                    key={session.id}
                    session={session}
                    isDeletable={Boolean(props.activeDate)}
                    selectedSessions={props.list[listItem]}
                    product={props.product}
                    option={props.option}
                    loadedSessionOptions={props.loadedSessionOptions}
                    selectedOptions={props.selectedOptions}
                    handleChangeQtyOption={props.handleChangeQtyOption}
                    handleChangeQtySession={props.handleChangeQtySession}
                    sessionOptionsList={props.sessionOptionsList}
                    exchangeQty={props.exchangeQty}
                    totalAllQty={props.totalAllQty}
                  />
                ) : (
                  <SessionListItem
                    key={session.id}
                    item={session}
                    isDeletable={Boolean(props.activeDate)}
                    handleChangeQtySession={props.handleChangeQtySession}
                    product={props.product}
                    unitPrice={props.unitPrice}
                    exchangeQty={props.exchangeQty}
                    totalAllQty={props.totalAllQty}
                  />
                )
              ) : null
            )}
          </Fragment>
        );
      }
    })}
  </>
);

export default SessionList;
