import React, { useCallback, useEffect, useState } from 'react';
import SimplePrice from '../Price/SimplePrice';
import Quantity from '../Quantity/Quantity';
import { formatMoney } from '../../services/formatMoney';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import OptionModel from '../../Models/OptionModel';
import { shopConfig } from '../../Globals/ShopConfig';
import SessionOptionModel from '../../Models/SessionOptionModel';
import { OptionsChildProps } from '../MultipleChoice/TicketOptions';
import OptionRenderingValuesModel from '../../Models/OptionRenderingValuesModel';
import './Options.css';

interface OptionListItemProps extends OptionsChildProps {
  item: OptionRenderingValuesModel;
  currentOptionList: SessionOptionModel[];
  totalAllQty: number;
  exchangeQty?: number;
}

const OptionListItem = (props: OptionListItemProps) => {
  const { isDeletable, session, product, item } = props;
  const [currentOption, setCurrentOption] = useState<OptionModel>();
  const [currentOptionRemaining, setCurrentOptionRemaining] =
    useState<SessionOptionModel | null>(null);
  const [totalOptionQty, setTotalOptionQty] = useState(0);
  const [selectedStateOptions, setSelectedStateOptions] = useState<
    OptionModel[]
  >([]);
  const currency = shopConfig.locale.currencyCode;
  const locale = shopConfig.locale.locale;

  const getOptionItem = useCallback(
    (sessionOptions: SessionOptionModel[]) => {
      let currentOptionRemaining = sessionOptions.find(
        (item) => item.option === props.item.formule.id
      );
      if (currentOptionRemaining) {
        setCurrentOptionRemaining(currentOptionRemaining);
      } else {
        setCurrentOptionRemaining(null);
      }
    },
    [props.item.formule?.id]
  );

  const changeQtySession = useCallback(
    (session, total) => {
      props.handleChangeQtySession(session, total);
    },
    [props]
  );

  const handleChangeSession = useCallback(
    (selectedOptions: OptionModel[], changeSession: boolean) => {
      if (props.session) {
        let total = 0;
        selectedOptions
          .filter((option) => option.sessionId === props.session.id)
          .forEach((option) => (total += option.quantity));

        if (changeSession) {
          changeQtySession(props.session, total);
        }
        setTotalOptionQty(total);
      }
    },
    [changeQtySession, props.session]
  );

  const getCurrentItem = useCallback(
    (options: OptionModel[]) => {
      let newCurrentOption;

      if (session) {
        newCurrentOption = options.find(
          (option) =>
            option.formule.id === item.formule.id &&
            option.sessionId === session.id
        );
      } else {
        newCurrentOption = options.find(
          (option) => option.formule.id === item.formule.id
        );
      }

      if (newCurrentOption) {
        setCurrentOption(new OptionModel(newCurrentOption));
      } else {
        setCurrentOption(new OptionModel(item));
      }
    },
    [item, session]
  );

  useEffect(() => {
    if (props.selectedOptions.length !== selectedStateOptions.length) {
      handleChangeSession(props.selectedOptions, true);
      setSelectedStateOptions(props.selectedOptions);
    }
    getCurrentItem(props.selectedOptions);
  }, [
    getCurrentItem,
    handleChangeSession,
    props.selectedOptions,
    selectedStateOptions.length,
  ]);

  useEffect(() => {
    if (props.selectedSessions) {
      handleChangeSession(props.selectedOptions, false);
      getCurrentItem(props.selectedOptions);
    }
  }, [
    getCurrentItem,
    handleChangeSession,
    props.selectedOptions,
    props.selectedSessions,
  ]);

  useEffect(() => {
    if (props.currentOptionList) {
      getOptionItem(props.currentOptionList);
    }
  }, [getOptionItem, props.currentOptionList]);

  const handleMin = () => {
    const quantityIncrement = product.quantityIncrement
      ? product.quantityIncrement
      : 1;

    const newQuantity = currentOption?.quantity - quantityIncrement;

    props.handleChangeQtyOption(currentOption, newQuantity, session);
  };

  const handlePlus = () => {
    const quantityIncrement = product.quantityIncrement
      ? product.quantityIncrement
      : 1;

    const newQuantity = currentOption?.quantity + quantityIncrement;

    props.handleChangeQtyOption(currentOption, newQuantity, session);
  };

  const handleChange = (number: number) => {
    props.handleChangeQtyOption(currentOption, number, session);
  };

  const handleDelete = () => {
    props.handleChangeQtyOption(currentOption, 0, props.session);
  };

  const isOptionDisabledByQuota = (option: SessionOptionModel): boolean => {
    /*
     * quotaMax === null: no limit
     * quotaMax > 0: limited quantity available
     * quotaMax === 0: sale disabled
     */
    return option ? option.quotaMax === 0 : false;
  };

  return (
    <>
      {!isOptionDisabledByQuota(currentOptionRemaining) && (
        <div
          className={`option-list-item-container ${
            currentOptionRemaining?.qtyRemaining === 0 ||
            (currentOption?.qtyRemaining && 'disabled')
          }`}
        >
          <div className={'option-list-item'}>
            <div>
              <div className={'option-list-item-title'}>
                {currentOption?.name}
              </div>
              {currentOption?.description && (
                <div className={'option-list-item-desc'}>
                  <InfoCircleOutlined className={'info-icon'} />
                  <div>{currentOption?.description}</div>
                </div>
              )}
            </div>
            <div>
              <SimplePrice
                price={currentOption?.formule?.price}
                currency={currency}
              />
            </div>
            <div>
              <Quantity
                minQuantity={
                  currentOption?.formule?.quantityMinPerSale
                    ? currentOption?.formule.quantityMinPerSale
                    : 0
                }
                remainingQuantity={
                  currentOptionRemaining
                    ? currentOptionRemaining?.qtyRemaining
                    : currentOption?.qtyRemaining
                }
                quantityMaxPerSale={
                  currentOptionRemaining?.quotaMax
                    ? currentOptionRemaining.quotaMax
                    : currentOption?.formule?.quantityMaxPerSale
                    ? currentOption?.formule.quantityMaxPerSale
                    : product.quantityMaxPerSale
                }
                handleMin={handleMin}
                currentValue={currentOption?.quantity}
                handlePlus={handlePlus}
                handleChange={handleChange}
                disabled={
                  totalOptionQty === session?.qtyRemaining ||
                  props.totalAllQty === props.exchangeQty
                }
                isInput
              />
            </div>
            <div className={'hiddenOnMobile'}>
              {formatMoney(currency, locale, currentOption?.totalPrice)}
            </div>
          </div>

          {isDeletable && (
            <DeleteOutlined
              disabled={currentOption?.quantity === 0}
              onClick={handleDelete}
            />
          )}
        </div>
      )}
    </>
  );
};

export default OptionListItem;
