import React from 'react';
import { shopConfig, shopIsIframe } from '../../Globals/ShopConfig';
import Container from '../Container/Container';
import './PictureBanner.css';

const PictureBanner = () => {
  const bannerPictureUrl = shopConfig.branding.bannerPictureUrl;
  return (
    bannerPictureUrl &&
    !shopIsIframe && (
      <Container className="product-review-banner">
        <img className="banner" src={bannerPictureUrl} alt={'shop banner'} />
      </Container>
    )
  );
};

export default PictureBanner;
