import ShopLocaleModel, {
  appLanguagesType,
} from '../Models/ShopConfig/ShopLocaleModel';

export function getBestSupportedLocale(
  locale: string,
  shopLocale: ShopLocaleModel
): string {
  if (languageExistInApp(shopLocale.appLanguages, locale)) {
    return locale;
  }
  const lang = locale.split(/_/)[0];
  if (languageExistInApp(shopLocale.appLanguages, lang)) {
    return lang;
  }
  if (languageExistInApp(shopLocale.appLanguages, shopLocale.defaultLanguage)) {
    return shopLocale.defaultLanguage;
  }
  return shopLocale.appLanguages[0].lang;
}

const languageExistInApp = (appLanguages: appLanguagesType[], lang: string) => {
  return appLanguages.some((appLang) => appLang.lang === lang);
};
