import { attendeeConstants } from '../constants/attendee.constants';
import AttendeeFormModel from '../../Models/Form/AttendeeFormModel';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  attendeeForm: null,
  newAttendeeFrom: null,
};

const attendee = (state = initialState, action: any) => {
  switch (action.type) {
    case attendeeConstants.GET_ATTENDEE_FORM_REQUEST: {
      return {
        ...state,
        attendeeForm: { form: [], error: null, loading: true },
      };
    }

    case attendeeConstants.GET_ATTENDEE_FORM_SUCCESS: {
      return {
        ...state,
        attendeeForm: {
          form: action.form.map((current) => new AttendeeFormModel(current)),
          error: null,
          loading: false,
        },
      };
    }

    case attendeeConstants.GET_ATTENDEE_FORM_FAILURE: {
      return {
        ...state,
        attendeeForm: {
          form: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case attendeeConstants.POST_ATTENDEE_FORM_REQUEST: {
      return {
        ...state,
        newAttendeeFrom: { form: [], error: null, loading: true },
      };
    }

    case attendeeConstants.POST_ATTENDEE_FORM_SUCCESS: {
      return {
        ...state,
        newAttendeeFrom: {
          form: action.response,
          error: null,
          loading: false,
        },
      };
    }

    case attendeeConstants.POST_ATTENDEE_FORM_FAILURE: {
      return {
        ...state,
        newAttendeeFrom: {
          form: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default attendee;
