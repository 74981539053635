import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShoppingCartOutlined, SwapOutlined } from '@ant-design/icons';
import { formatMoney } from '../../services/formatMoney';
import EmisysButton from '../EmisysButton/EmisysButton';
import { postBasketMultipleItemRequest } from '../../redux/actions/basket.actions';
import { dispatchAddToCart } from '../../services/tracking';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import ProductModel from '../../Models/ProductModel';
import SessionModel from '../../Models/SessionModel';
import OptionModel from '../../Models/OptionModel';
import { checkReduxResponse } from '../../services/httpService';
import { postExchangeBarcodeRequest } from '../../redux/actions/barcodes.actions';
import BarcodeModel from '../../Models/BarcodeModel';
import { storeType } from '../../index';
import './Drawer.css';

interface DrawerFooterInterface {
  totalAllPrice: number;
  options: OptionModel[];
  sessions: {
    [date: string]: SessionModel[];
  };
  product: ProductModel;
  selectedSeats: any[];
  currentSession: SessionModel;
  disabledBtn: boolean;
  totalAllQty: number;
  exchangeQty?: number;
  barcode?: BarcodeModel;
  onCancel: () => void;
}

const DrawerFooter = (props: DrawerFooterInterface) => {
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const currentBasket = useSelector(
    (state: storeType) => state.basket.currentBasket
  );
  const newExchangeBarcode = useSelector(
    (state: storeType) => state.barcodes.newExchangeBarcode
  );

  const postExchangeProduct = useCallback(
    (product: any) => {
      if (props.barcode) {
        dispatch(
          postExchangeBarcodeRequest(product, {
            id: props.barcode.id,
            signature: props.barcode.signature,
          })
        );
      }
    },
    [dispatch, props.barcode]
  );

  const postBasketMultipleItem = useCallback(
    (items: any) => {
      dispatch(postBasketMultipleItemRequest(items));
    },
    [dispatch]
  );

  useEffect(() => {
    if (newExchangeBarcode) {
      setLoadingButton(newExchangeBarcode.loading);
      if (checkReduxResponse(newExchangeBarcode, 'barcode')) {
        props.onCancel();
      }
    }
  }, [newExchangeBarcode, props]);

  useEffect(() => {
    if (currentBasket) {
      setLoadingButton(currentBasket.loading);
    }
  }, [currentBasket]);

  const handleConfirm = () => {
    let multipleItemPayload = [];

    if (props.selectedSeats.length > 0) {
      props.selectedSeats.forEach((currentSeat) => {
        const currentProductId = currentSeat?.selectedTicketType
          ? currentSeat.selectedTicketType
          : props.product.id.toString();

        const currentIndex = multipleItemPayload.findIndex(
          (obj) => obj.formuleId === currentProductId
        );

        if (currentIndex > -1) {
          multipleItemPayload[currentIndex].quantity += 1;
          multipleItemPayload[currentIndex].placementUnits.push(
            currentSeat.seatId
          );
        } else {
          multipleItemPayload.push({
            quantity: 1,
            formuleId: currentProductId,
            sessionId: props.currentSession.id,
            placementUnits: [currentSeat.seatId],
            placementHoldToken: currentSeat.chart.holdToken,
          });
        }

        dispatchAddToCart(currentSeat.label, currentSeat.price);
      });
    } else {
      if (props.options.length > 0) {
        props.options.forEach((option: OptionModel) => {
          multipleItemPayload.push({
            formuleId: option.formule.id,
            quantity: option.quantity,
            sessionId: option.sessionId ? option.sessionId : null,
            project: shopConfig.project.name,
            pos: shopConfig.pos.slug,
          });

          dispatchAddToCart(option.formule.name, option.formule.price);
        });
      } else if (Object.keys(props.sessions).length > 0) {
        Object.values(props.sessions).forEach((session: SessionModel[]) => {
          session.forEach((data: SessionModel) => {
            multipleItemPayload.push({
              formuleId: props.product.id,
              quantity: data.quantity,
              sessionId: data.id,
              project: shopConfig.project.name,
              pos: shopConfig.pos.slug,
            });

            dispatchAddToCart(props.product.name, props.product.price.amount);
          });
        });
      }
    }

    postBasketMultipleItem({ items: multipleItemPayload });
  };

  const handleConfirmExchange = () => {
    const newBasket = {
      formule_id: null,
      session_id: null,
      placementUnits: null,
      placementHoldToken: null,
    };

    if (props.selectedSeats.length > 0) {
      props.selectedSeats.forEach((currentSeat) => {
        console.log('=>(DrawerFooter.tsx:155) currentSeat', currentSeat);
        const currentProductId = currentSeat?.selectedTicketType
          ? currentSeat.selectedTicketType
          : props.product.id;

        newBasket.formule_id = currentProductId;
        newBasket.session_id = props.currentSession.id;
        newBasket.placementUnits = [currentSeat.seatId];
        newBasket.placementHoldToken = currentSeat.chart.holdToken;

        dispatchAddToCart(currentSeat.label, currentSeat.price);
      });
    } else {
      if (props.options.length > 0) {
        props.options.forEach(
          (option) => (newBasket.formule_id = option.formule.id)
        );
      } else {
        newBasket.formule_id = props.product.id;
      }

      if (Object.keys(props.sessions).length > 0) {
        Object.values(props.sessions).forEach((session) => {
          session.forEach((data) => (newBasket.session_id = data.id));
        });
      }
    }

    postExchangeProduct(newBasket);
  };

  return (
    <div className={'confirm-drawer-container'}>
      <div className={'drawer-total-price'}>
        {formatMoney(
          shopConfig.locale.currencyCode,
          shopConfig.locale.locale,
          props.totalAllPrice
        )}
      </div>
      <div className={'drawer-btn-add-to-basket'}>
        <EmisysButton
          disabled={
            props.disabledBtn ||
            (props.exchangeQty && props.totalAllQty !== props.exchangeQty)
          }
          loading={loadingButton}
          onClick={props.exchangeQty ? handleConfirmExchange : handleConfirm}
          icon={props.exchangeQty ? <SwapOutlined /> : <ShoppingCartOutlined />}
        >
          {props.exchangeQty
            ? Translator.trans('word.action.exchange')
            : Translator.trans('word.action.add')}
        </EmisysButton>
      </div>
    </div>
  );
};

export default DrawerFooter;
