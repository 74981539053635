import { ticketsConstants } from '../constants/tickets.constants';
import CategoriesModel from '../../Models/CategoriesModel';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  ticketsList: null,
  ticketDetails: null,
};

const tickets = (state = initialState, action: any) => {
  switch (action.type) {
    case ticketsConstants.GET_TICKETS_REQUEST: {
      return {
        ...state,
        ticketsList: { tickets: [], error: null, loading: true },
      };
    }

    case ticketsConstants.GET_TICKETS_SUCCESS: {
      return {
        ...state,
        ticketsList: {
          tickets: new CategoriesModel(action.tickets),
          error: null,
          loading: false,
        },
      };
    }

    case ticketsConstants.GET_TICKETS_FAILURE: {
      return {
        ...state,
        ticketsList: {
          tickets: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case ticketsConstants.GET_TICKET_DETAILS_REQUEST: {
      return {
        ...state,
        ticketDetails: { ticket: null, error: null, loading: true },
      };
    }

    case ticketsConstants.GET_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        ticketDetails: { ticket: action.ticket, error: null, loading: false },
      };
    }

    case ticketsConstants.GET_TICKET_DETAILS_FAILURE: {
      return {
        ...state,
        ticketDetails: {
          ticket: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default tickets;
