import React from 'react';
import './SuccessErrorIcon.css';

const SuccessErrorIcon = ({ success }: { success?: boolean }) => (
  <div className={'success-error-container'}>
    <svg height="120" width="120" viewBox="0 0 48 48" aria-hidden="true">
      {success ? (
        <>
          <circle
            className="success-circle"
            fill="#5bb543"
            cx="24"
            cy="24"
            r="22"
          />
          <path
            className="success-tick"
            fill="none"
            stroke="#FFF"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M14 27l5.917 4.917L34 17"
          />
        </>
      ) : (
        <>
          <circle
            className="error-circle"
            fill="#FF3939"
            cx="24"
            cy="24"
            r="22"
          />
          <path
            className="error-cross"
            fill="none"
            stroke="#FFF"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M15 15 L33 33 M33 15 L15 33"
          />
        </>
      )}
    </svg>
  </div>
);

export default SuccessErrorIcon;
