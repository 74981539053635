import { orderConstants } from '../constants/order.constants';

export const getOrdersListRequest = () => ({
  type: orderConstants.GET_ORDERS_LIST_REQUEST,
});

export const getOrdersListSuccess = (orders: any[]) => ({
  type: orderConstants.GET_ORDERS_LIST_SUCCESS,
  orders,
});

export const getOrdersListFailure = (error: object) => ({
  type: orderConstants.GET_ORDERS_LIST_FAILURE,
  error,
});

export const getOrderDetailRequest = (params) => ({
  type: orderConstants.GET_ORDER_DETAIL_REQUEST,
  params,
});

export const getOrderDetailSuccess = (order: any) => ({
  type: orderConstants.GET_ORDER_DETAIL_SUCCESS,
  order,
});

export const getOrderDetailFailure = (error: object) => ({
  type: orderConstants.GET_ORDER_DETAIL_FAILURE,
  error,
});

export const getOrderOverviewRequest = () => ({
  type: orderConstants.GET_ORDER_OVERVIEW_REQUEST,
});

export const getOrderOverviewSuccess = (order: object) => ({
  type: orderConstants.GET_ORDER_OVERVIEW_SUCCESS,
  order,
});

export const getOrderOverviewFailure = (error: object) => ({
  type: orderConstants.GET_ORDER_OVERVIEW_FAILURE,
  error,
});

export const postOrderConfirmRequest = (order: object) => ({
  type: orderConstants.POST_ORDER_CONFIRM_REQUEST,
  order,
});

export const postOrderConfirmSuccess = (response: object) => ({
  type: orderConstants.POST_ORDER_CONFIRM_SUCCESS,
  response,
});

export const postOrderConfirmFailure = (error: object) => ({
  type: orderConstants.POST_ORDER_CONFIRM_FAILURE,
  error,
});

export const getPendingOrderRequest = () => ({
  type: orderConstants.GET_PENDING_ORDER_REQUEST,
});

export const getPendingOrderSuccess = (response: object) => ({
  type: orderConstants.GET_PENDING_ORDER_SUCCESS,
  response,
});

export const getPendingOrderFailure = (error: object) => ({
  type: orderConstants.GET_PENDING_ORDER_FAILURE,
  error,
});

export const clearOrders = () => ({
  type: orderConstants.CLEAR_ORDERS,
});
