import { shopConfig } from '../Globals/ShopConfig';

export default class AddressModel {
  areaLevel: string;
  boxNumber: string;
  country: string;
  locality: string;
  name: string | null;
  postalCode: string;
  street: string;
  streetNumber: string;

  constructor(data: any) {
    this.name = data.name ?? null;
    this.areaLevel = data.areaLevel ?? '';
    this.boxNumber = data.boxNumber ?? '';
    this.country = data.country ?? '';
    this.locality = data.locality ?? '';
    this.postalCode = data.postalCode ?? '';
    this.street = data.street ?? '';
    this.streetNumber = data.streetNumber ?? '';
  }

  getFullStreet() {
    return [this.street, this.streetNumber, this.boxNumber]
      .filter((n) => n)
      .join(', ');
  }

  getFullCity() {
    return [this.postalCode, this.locality].filter((n) => n).join(', ');
  }

  getFullCountry() {
    if (this.country) {
      let regionNames = new Intl.DisplayNames(
        [shopConfig.locale.locale.replace('_', '-')],
        {
          type: 'region',
        }
      );
      return regionNames.of(this.country);
    }

    return '';
  }
}
