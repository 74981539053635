import React from 'react';
import ModalEmisys from './ModalEmisys';
import { BarcodeForwardModel } from '../../Models/BarcodeModel';
import './Modal.css';
import getBarcodeStatus from '../../app/UserBarcodes/UserBarcodeItem/getBarcodeStatus';

interface ModalTransferProps {
  isOpen: boolean;
  handleClose: () => void;
  forwardList: BarcodeForwardModel[];
}

const ModalTransferList = (props: ModalTransferProps) => {
  return (
    <ModalEmisys
      closable={false}
      open={props.isOpen}
      onCancel={props.handleClose}
      footer={null}
      width={'small'}
      centered
    >
      <div className={'simple-modal'}>
        {props.forwardList.map((forward: BarcodeForwardModel) => (
          <div key={forward.id} className={'forward-list-item'}>
            <div>
              <div>
                {forward.recipientFirstName} {forward.recipientLastName}
              </div>
              <div>{forward.recipientEmail}</div>
            </div>
            <div>{getBarcodeStatus(forward.status)}</div>
          </div>
        ))}
      </div>
    </ModalEmisys>
  );
};

export default ModalTransferList;
