export default class ShopRouterModel {
  datesListUrl: string;
  paymentmethodListUrl: string;
  accommodationListUrl: string;
  accommodationAssemblyListUrl: string;
  accommodationOccupantTypeListUrl: string;
  accommodationDetailUrl: string;
  productTicketListUrl: string;
  productSectionListUrl: string;
  productSessionListUrl: string;
  productOptionListUrl: string;
  productOptionSessionListUrl: string;
  productDetailUrl: string;
  basketDetailUrl: string;
  basketRemoveItemUrl: string;
  basketRemoveAllUrl: string;
  basketAddOneItemUrl: string;
  basketAddMultiItemUrl: string;
  basketAddDiscountUrl: string;
  basketUpdateItemUrl: string;
  basketAddAccommodationUrl: string;
  basketUpsellingUrl: string;
  pageTermsUrl: string;
  pageLegalUrl: string;
  pagePolicyUrl: string;
  pageInfoUrl: string;
  authEmailCheckUrl: string;
  authRegisterUrl: string;
  authLoginUrl: string;
  authLoginPrivateShopUrl: string;
  authPasswordRenewUrl: string;
  authPasswordLostUrl: string;
  authGoogleConnectCallbackUrl: string;
  authFacebookConnectCallbackUrl: string;
  authAppleConnectCallbackUrl: string;
  authAppleConnecRedirectUrl: string;
  userProfileUrl: string;
  userCustomerFormUrl: string;
  userCustomerFormSaveUrl: string;
  userAttendeeFormUrl: string;
  userAttendeeFormSaveUrl: string;
  userOrderListUrl: string;
  orderReviewUrl: string;
  orderConfirmUrl: string;
  orderCancelUrl: string;
  orderPendingListUrl: string;
  paymentStartUrl: string;
  paymentDetailUrl: string;
  paymentDebugValidateUrl: string;
  paymentDebugConfirmUrl: string;
  paymentDebugCancelUrl: string;
  paymentPayconiqCancelUrl: string;
  paymentSquareupValidateUrl: string;
  paymentSquareupCancelUrl: string;
  productOptionPlacementListUrl: string;
  userOrderDetailUrl: string;
  barcodeDownloadPdfUrl: string;
  barcodeDownloadApplePassUrl: string;
  barcodeDownloadGooglePassUrl: string;
  barcodeAttendeeFormSaveUrl: string;
  barcodeAttendeeFormUrl: string;
  barcodeExchangeListUrl: string;
  barcodeExchangeSaveUrl: string;
  barcodeForwardSendUrl: string;
  userBarcodeListUrl: string;

  constructor(data: any) {
    this.datesListUrl = data.datesListUrl;
    this.paymentmethodListUrl = data.paymentmethodListUrl;
    this.accommodationListUrl = data.accommodationListUrl;
    this.accommodationAssemblyListUrl = data.accommodationAssemblyListUrl;
    this.accommodationOccupantTypeListUrl =
      data.accommodationOccupantTypeListUrl;
    this.accommodationDetailUrl = data.accommodationDetailUrl;
    this.productTicketListUrl = data.productTicketListUrl;
    this.productSectionListUrl = data.productSectionListUrl;
    this.productSessionListUrl = data.productSessionListUrl;
    this.productOptionListUrl = data.productOptionListUrl;
    this.productOptionSessionListUrl = data.productOptionSessionListUrl;
    this.productDetailUrl = data.productDetailUrl;
    this.basketDetailUrl = data.basketDetailUrl;
    this.basketRemoveItemUrl = data.basketRemoveItemUrl;
    this.basketRemoveAllUrl = data.basketRemoveAllUrl;
    this.basketAddOneItemUrl = data.basketAddOneItemUrl;
    this.basketAddMultiItemUrl = data.basketAddMultiItemUrl;
    this.basketAddDiscountUrl = data.basketAddDiscountUrl;
    this.basketUpdateItemUrl = data.basketUpdateItemUrl;
    this.basketAddAccommodationUrl = data.basketAddAccommodationUrl;
    this.basketUpsellingUrl = data.basketUpsellingUrl;
    this.pageTermsUrl = data.pageTermsUrl;
    this.pageLegalUrl = data.pageLegalUrl;
    this.pagePolicyUrl = data.pagePolicyUrl;
    this.pageInfoUrl = data.pageInfoUrl;
    this.authEmailCheckUrl = data.authEmailCheckUrl;
    this.authRegisterUrl = data.authRegisterUrl;
    this.authLoginUrl = data.authLoginUrl;
    this.authLoginPrivateShopUrl = data.authLoginPrivateShopUrl;
    this.authPasswordRenewUrl = data.authPasswordRenewUrl;
    this.authPasswordLostUrl = data.authPasswordLostUrl;
    this.authGoogleConnectCallbackUrl = data.authGoogleConnectCallbackUrl;
    this.authFacebookConnectCallbackUrl = data.authFacebookConnectCallbackUrl;
    this.authAppleConnectCallbackUrl = data.authAppleConnectCallbackUrl;
    this.authAppleConnecRedirectUrl = data.authAppleConnecRedirectUrl;
    this.userProfileUrl = data.userProfileUrl;
    this.userCustomerFormUrl = data.userCustomerFormUrl;
    this.userCustomerFormSaveUrl = data.userCustomerFormSaveUrl;
    this.userAttendeeFormUrl = data.userAttendeeFormUrl;
    this.userAttendeeFormSaveUrl = data.userAttendeeFormSaveUrl;
    this.userOrderListUrl = data.userOrderListUrl;
    this.userOrderDetailUrl = data.userOrderDetailUrl;
    this.orderReviewUrl = data.orderReviewUrl;
    this.orderConfirmUrl = data.orderConfirmUrl;
    this.orderCancelUrl = data.orderCancelUrl;
    this.orderPendingListUrl = data.orderPendingListUrl;
    this.paymentStartUrl = data.paymentStartUrl;
    this.paymentDetailUrl = data.paymentDetailUrl;
    this.paymentDebugValidateUrl = data.paymentDebugValidateUrl;
    this.paymentDebugConfirmUrl = data.paymentDebugConfirmUrl;
    this.paymentDebugCancelUrl = data.paymentDebugCancelUrl;
    this.paymentPayconiqCancelUrl = data.paymentPayconiqCancelUrl;
    this.paymentSquareupValidateUrl = data.paymentSquareupValidateUrl;
    this.paymentSquareupCancelUrl = data.paymentSquareupCancelUrl;
    this.productOptionPlacementListUrl = data.productOptionPlacementListUrl;
    this.barcodeDownloadPdfUrl = data.barcodeDownloadPdfUrl;
    this.barcodeDownloadApplePassUrl = data.barcodeDownloadApplePassUrl;
    this.barcodeDownloadGooglePassUrl = data.barcodeDownloadGooglePassUrl;
    this.barcodeAttendeeFormSaveUrl = data.barcodeAttendeeFormSaveUrl;
    this.barcodeAttendeeFormUrl = data.barcodeAttendeeFormUrl;
    this.barcodeExchangeListUrl = data.barcodeExchangeListUrl;
    this.barcodeExchangeSaveUrl = data.barcodeExchangeSaveUrl;
    this.barcodeForwardSendUrl = data.barcodeForwardSendUrl;
    this.userBarcodeListUrl = data.userBarcodeListUrl;
  }
}
