import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import EmisysButton from '../EmisysButton/EmisysButton';
import Price from '../Price/Price';
import ProductItemIcon from '../ProductItemIcon/ProductItemIcon';
import { postExchangeBarcodeRequest } from '../../redux/actions/barcodes.actions';
import Translator from '../../services/translator';
import { shopConfig } from '../../Globals/ShopConfig';
import ProductModel from '../../Models/ProductModel';
import DrawerProduct from '../../components/Drawer/DrawerProduct';
import BarcodeModel from '../../Models/BarcodeModel';
import { SwapOutlined } from '@ant-design/icons';
import './ExchangeProductItem.css';

const ExchangeProductItem = (props: {
  product: ProductModel;
  oldProduct: ProductModel;
  barcode: BarcodeModel;
  isCurrentProduct?: boolean;
  qty: number;
}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const postExchangeProduct = useCallback(
    (product: any) => {
      dispatch(
        postExchangeBarcodeRequest(product, {
          id: props.barcode.id,
          signature: props.barcode.signature,
        })
      );
    },
    [dispatch, props.barcode]
  );

  const handleSelectTicket = () => {
    if (props.product.hasOptions || props.product.hasSessions) {
      setOpenModal(true);
    }

    if (!props.product.hasSessions && !props.product.hasOptions) {
      postExchangeProduct({
        formule_id: props.product.id,
        session_id: null,
      });
    }
  };

  return (
    <>
      <div className="exchange-item-container">
        <ProductItemIcon
          product={props.product}
          shopShowFormulePicture={shopConfig.pos.showProductPicture}
        />
        <div className="exchange-item-info">
          <div>
            <div className="exchange-item-name">
              <span>{props.product.name}</span>
            </div>
            <div className="exchange-item-desc">
              <ReactMarkdown>{props.product.shortDescription}</ReactMarkdown>
            </div>
          </div>

          <div className="exchange-item-price">
            <Price
              currentPrice={props.product.price.amount}
              currency={props.product.price.currency}
            />

            <div className="exchange-quanity">
              {props.isCurrentProduct ? (
                <>
                  {(props.product.hasSessions || props.product.hasOptions) && (
                    <EmisysButton
                      onClick={handleSelectTicket}
                      icon={<SwapOutlined />}
                    >
                      {Translator.trans('word.action.exchange')}
                    </EmisysButton>
                  )}
                </>
              ) : (
                <EmisysButton
                  onClick={handleSelectTicket}
                  icon={<SwapOutlined />}
                >
                  {Translator.trans('word.action.exchange')}
                </EmisysButton>
              )}
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <DrawerProduct
          isOpen={openModal}
          product={props.product}
          onCancel={() => setOpenModal(false)}
          exchangeQty={props.qty}
          barcode={props.barcode}
        />
      )}
    </>
  );
};

export default ExchangeProductItem;
