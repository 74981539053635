import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Col, Form, Radio, Row, Spin } from 'antd';
import Container from '../../../components/Container/Container';
import Title from '../../../components/Title/Title';
import EmisysButton from '../../../components/EmisysButton/EmisysButton';
import AddPromotion from '../../../components/AddPromotion/AddPromotion';
import { formatMoney } from '../../../services/formatMoney';
import {
  getOrderOverviewRequest,
  postOrderConfirmRequest,
} from '../../../redux/actions/order.actions';
import { checkReduxResponse } from '../../../services/httpService';
import {
  getBasketRequest,
  postBasketDiscountRequest,
} from '../../../redux/actions/basket.actions';
import BasketPriceDetail from '../../../components/BasketPriceDetail/BasketPriceDetail';
import {
  openNotificationError,
  showMessageError,
} from '../../../components/Notification/Notification';
import { shopConfig, shopIsIframe } from '../../../Globals/ShopConfig';
import { orderCheckBoxId } from '../../../Models/OrderOverview/OrderCheckboxModel';
import InvoiceContainer from './Invoice/Invoice';
import { validateFormMessages } from '../../../services/validateFormMessage';
import { dispatchInitiatePayment } from '../../../services/tracking';
import Provider from '../../../components/Providers/Provider';
import Translator from '../../../services/translator';
import {
  BasketStatusConstant,
  CONSTANTSCMS,
  currencyPrecision,
  pagesConstant,
} from '../../../Constants/Constants';
import { getBaseUrl } from '../../../services/parseUrl';
import history from '../../../services/history';
import Timer from '../../../components/Timer/Timer';
import IframeOverlap from '../../../components/IframeOverlap/IframeOverlap';
import '../index.css';

const DisplayCheckboxes = ({ checkboxes, changeCheckBox }) => (
  <div className={'payment-checkBox-container'}>
    {checkboxes.map((element, index) => (
      <Form.Item
        key={index}
        name={element.id}
        initialValue={element.value}
        rules={[
          {
            validator: () =>
              !element.required || element.value
                ? Promise.resolve()
                : Promise.reject(
                    new Error(Translator.trans('form.validate.checkbox'))
                  ),
          },
        ]}
      >
        <Checkbox
          checked={element.value}
          onChange={(event) => changeCheckBox(event, index)}
          className={'payment-checkbox'}
        >
          <div
            className={'html-checkbox-content'}
            dangerouslySetInnerHTML={{
              __html: element.label,
            }}
          />
        </Checkbox>
      </Form.Item>
    ))}
  </div>
);

const DisplayPaymentButton = ({ price, loading, error }) => (
  <EmisysButton
    className={'payment-container-button'}
    htmlType="submit"
    loading={loading}
    disabled={error || shopConfig?.pos?.preview}
  >
    {shopConfig?.pos?.preview
      ? Translator.trans('shop.preview.banner.text')
      : Math.abs(price) < currencyPrecision
      ? Translator.trans('order.button.confirm')
      : Translator.trans('order.button.confirmAndPay', {
          amount: formatMoney(
            shopConfig.locale.currencyCode,
            shopConfig.locale.locale,
            price
          ),
        })}
  </EmisysButton>
);

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentCancelError: false,
      isError: false,
      loading: true,
      loadingPromotion: false,
      loadingSend: false,
      paymentMethods: [],
      checkboxes: [],
      currentPaymentMethod: null,
      totalPriceWithoutVat: 0,
      totalPriceWithVat: 0,
      vatAmount: 0,
      providerData: null,
      isInvoice: false,
      errorMessage: null,
      isIframePaymentOpen: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.getBasket();

    if (this.props.paymentMethodList?.paymentMethod) {
      this.setPaymentMethods(this.props.paymentMethodList.paymentMethod);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.orderOverview !== prevProps.orderOverview) {
      this.setState({
        loading: this.props.orderOverview.loading,
        isError: this.props.orderOverview.error,
        errorMessage: null,
      });
      if (checkReduxResponse(this.props.orderOverview, 'order')) {
        this.setBasketItems(this.props.orderOverview.order.basket);
        this.setCheckBoxes(this.props.orderOverview.order.checkboxes, () => {
          const termsButton = document
            .querySelector('.html-checkbox-content')
            ?.querySelector('a');
          if (termsButton) {
            termsButton.href =
              getBaseUrl(window.location.pathname) +
              '/page/' +
              CONSTANTSCMS.TERMSURL;
            termsButton.target = '_blank';
          }
        });
      }

      if (this.props.orderOverview.error) {
        this.setState({ errorMessage: this.props.orderOverview.error.message });
      }
    }

    if (this.props.orderConfirm !== prevProps.orderConfirm) {
      this.setState({ loadingSend: this.props.orderConfirm.loading });

      if (checkReduxResponse(this.props.orderConfirm, 'order')) {
        if (this.props.orderConfirm.order?.isNoPayment) {
          const order = this.props.orderConfirm.order;
          history.navigate({
            pathname: pagesConstant.order.success,
            search: '?id=' + order.id + '&signature=' + order.signature,
          });
        } else {
          this.setState({ providerData: this.props.orderConfirm.order });
        }
      }

      if (this.props.orderConfirm.error) {
        openNotificationError('', this.props.orderConfirm.error.message);
        if (this.props.orderConfirm.error.errors) {
          this.handleDisplayErrors(this.props.orderConfirm.error.errors);
        }
      }
    }

    if (prevProps.currentBasket !== this.props.currentBasket) {
      this.setState({ loading: this.props.currentBasket.loading });
      if (checkReduxResponse(this.props.currentBasket, 'basket')) {
        const searchParams = new URLSearchParams();
        searchParams.set('id', this.props.currentBasket.basket.id);
        searchParams.set(
          'signature',
          this.props.currentBasket.basket.signature
        );
        if (
          this.props.currentBasket.basket.status ===
          BasketStatusConstant.payment_accept
        ) {
          history.navigate({
            pathname: pagesConstant.order.success,
            search: '?' + searchParams,
          });
        } else if (
          this.props.currentBasket.basket.status === BasketStatusConstant.cancel
        ) {
          history.navigate({
            pathname: pagesConstant.order.cancel,
            search: '?' + searchParams,
          });
        } else {
          this.props.getOrderOverview();
        }
      }

      if (this.props.currentBasket.error) {
        this.setState({
          isError: true,
          errorMessage: this.props.currentBasket.error.message,
        });
      }
    }
  }

  handleDisplayErrors = (errors) => {
    let setErrors = errors.map((error) => ({
      name: error.fieldName,
      errors: [error.message],
    }));

    if (setErrors.length > 0) {
      this.formRef.current?.setFields(setErrors);
      this.formRef.current?.scrollToField(setErrors[0].name);
    }
  };

  handleRemoveErrors = (obj) => {
    let errors = Object.keys(obj).map((key) => ({
      name: key,
      errors: '',
    }));

    this.formRef.current?.setFields(errors);
  };

  setBasketItems = (basket) => {
    this.setState(
      {
        totalPriceWithoutVat: basket.totalPriceWithoutVat,
        totalPriceWithVat: basket.totalPriceWithVat,
        subTotal: basket.subTotal,
        feeAmount: basket.feeAmount,
        taxDetails: basket.taxDetails,
      },
      () => {
        this.setPaymentMethods(this.props.orderOverview.order.paymentMethods);
      }
    );
  };

  setPaymentMethods = (paymentMethods) => {
    this.setState({ paymentMethods: paymentMethods });
    if (Math.abs(this.state.totalPriceWithVat) >= currencyPrecision) {
      this.setState({ currentPaymentMethod: paymentMethods[0] });
    }
  };

  setCheckBoxes = (checkboxes, callback) => {
    const isInvoice = checkboxes.find(
      (checkbox) => checkbox.id === orderCheckBoxId.requestInvoice
    );

    this.setState(
      {
        checkboxes: checkboxes,
        isInvoice: isInvoice?.value,
      },
      () => {
        callback && callback();
      }
    );
  };

  handleConfirmPayment = () => {
    const {
      checkboxes,
      currentPaymentMethod,
      isInvoice,
      currency,
      totalPriceWithVat,
    } = this.state;
    let overviewParams = {};

    overviewParams.payment_method_id = currentPaymentMethod
      ? currentPaymentMethod.id
      : null;

    checkboxes.forEach((element) => {
      overviewParams[element.id] = element.value;
    });

    if (isInvoice) {
      overviewParams['billing'] = this.props.invoiceInfo;
    }

    this.handleRemoveErrors(this.formRef.current?.getFieldsValue());
    dispatchInitiatePayment({
      currency: currency,
      value: totalPriceWithVat,
    });
    this.props.postOrderConfirm(overviewParams);

    if (shopIsIframe) {
      this.setState({ isIframePaymentOpen: true });
      const origin = window.location.origin;
      const baseUrl = getBaseUrl(window.location.pathname);
      window.open(
        origin + baseUrl + '/loader',
        'audience_payment_iframe',
        'height=700, width=900, toolbar=no, menubar=yes, location=yes'
      );
    }
  };

  handleChangePaymentMethod = (event) => {
    this.setState({
      currentPaymentMethod: event.target.value,
      paymentCancelError: false,
    });
  };

  handleConfirmDiscount = (value) => {
    this.props.postDiscount({ code: value });
  };

  changeCheckBox = (event, index) => {
    const newCheckbox = [...this.state.checkboxes];
    newCheckbox[index].value = event.target.checked;

    this.setCheckBoxes(newCheckbox);
  };

  handleCancelProvider = () => {
    this.setState(
      {
        providerData: null,
        paymentCancelError: true,
        isIframePaymentOpen: false,
      },
      () => {
        this.props.getBasket();
      }
    );
  };

  render() {
    const {
      paymentCancelError,
      paymentMethods,
      currentPaymentMethod,
      checkboxes,
      loading,
      loadingSend,
      providerData,
      isError,
      isInvoice,
      errorMessage,
      isIframePaymentOpen,
    } = this.state;

    return (
      <>
        <Form
          ref={this.formRef}
          className={'validate-container-form'}
          onFinish={this.handleConfirmPayment}
          scrollToFirstError
          validateMessages={validateFormMessages}
        >
          <Spin spinning={loading}>
            <div className={'validate-container'}>
              <Col className="gutter-row" xs={24} sm={12}>
                <Container
                  className={'payment-container'}
                  disabled={
                    Math.abs(this.state.totalPriceWithVat) < currencyPrecision
                  }
                >
                  <Title value={Translator.trans('payment.methods')} />
                  {paymentCancelError && (
                    <div className={'payment-error'}>
                      {Translator.trans('error.internal_error.title')}
                    </div>
                  )}
                  <div>{Translator.trans('payment.select')}</div>
                  <Radio.Group
                    className={'payment-methods-container-list'}
                    onChange={this.handleChangePaymentMethod}
                    value={currentPaymentMethod}
                  >
                    {paymentMethods.map((item, index) => (
                      <Container
                        key={index}
                        className={`payment-methods-item ${
                          currentPaymentMethod === item ? 'active' : ''
                        }`}
                      >
                        <Radio
                          className={'payment-methods-container'}
                          value={item}
                        >
                          <div className={'payment-method-image'}>
                            <img alt={item.name} src={item.picture} />
                          </div>
                        </Radio>
                      </Container>
                    ))}
                  </Radio.Group>
                </Container>
              </Col>

              <Col className="gutter-row" xs={24} sm={12}>
                <Timer showOnly />
                <Container className={'payment-container'}>
                  <Title value={Translator.trans('accounting.detail')} />

                  {this.props.basketEditable && (
                    <AddPromotion onConfirm={this.handleConfirmDiscount} />
                  )}

                  {errorMessage && (
                    <div>{showMessageError('', errorMessage)}</div>
                  )}

                  <BasketPriceDetail
                    subTotal={this.state.subTotal}
                    feeAmount={this.state.feeAmount}
                    totalPriceWithoutVat={this.state.totalPriceWithoutVat}
                    totalPriceWithVat={this.state.totalPriceWithVat}
                    taxDetails={this.state.taxDetails}
                  />

                  <DisplayCheckboxes
                    checkboxes={checkboxes}
                    changeCheckBox={this.changeCheckBox}
                  />

                  {!isInvoice && (
                    <DisplayPaymentButton
                      price={this.state.totalPriceWithVat}
                      loading={loadingSend}
                      error={isError}
                    />
                  )}
                </Container>
              </Col>

              {isInvoice && (
                <Col className="gutter-row" xs={24} sm={24}>
                  <Container className={'payment-container'}>
                    <InvoiceContainer
                      updateInvoiceInfo={this.props.updateInvoiceInfo}
                      invoiceInfo={this.props.invoiceInfo}
                    />

                    <Row justify="end">
                      <Col className="gutter-row" xs={24} sm={12}>
                        <DisplayPaymentButton
                          price={this.state.totalPriceWithVat}
                          loading={loadingSend}
                          error={isError}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>
              )}
            </div>
          </Spin>
        </Form>

        {providerData &&
          (shopIsIframe ? (
            <IframeOverlap
              order={providerData}
              closeIframeProvider={this.handleCancelProvider}
              isIframePaymentOpen={isIframePaymentOpen}
            />
          ) : (
            <Provider
              current={providerData}
              cancelProvider={this.handleCancelProvider}
            />
          ))}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  paymentMethodList: state.paymentMethod.paymentMethodList,
  currentBasket: state.basket.currentBasket,
  orderOverview: state.order.orderOverview,
  orderConfirm: state.order.orderConfirm,
});

const mapDispatchToProps = (dispatch) => ({
  getBasket: () => dispatch(getBasketRequest()),
  getOrderOverview: () => dispatch(getOrderOverviewRequest()),
  postDiscount: (discount) => dispatch(postBasketDiscountRequest(discount)),
  postOrderConfirm: (order) => dispatch(postOrderConfirmRequest(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
