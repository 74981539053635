// URL PATTERN = /__PROJECT_BRAND__/__POS_SLUG__/__LOCALE__/
import { getBestSupportedLocale } from './getBestSupportedLocale';
import ShopLocaleModel from '../Models/ShopConfig/ShopLocaleModel';

function parseUrl(pathname: string): string[] {
  return pathname.split('/').slice(1);
}

export function getBaseUrl(pathname: string): string {
  const element = parseUrl(pathname);

  return '/' + element.slice(0, 3).join('/');
}

export function getBaseUrlWithoutLang(pathname: string): string {
  const element = parseUrl(pathname);

  return '/' + element.slice(0, 2).join('/');
}

export function removeBaseUrl(pathname: string): string {
  const element = parseUrl(pathname);

  return '/' + element.slice(3).join('/');
}

export function getConfigParams(pathname: string): {
  project: string;
  pos: string;
  _locale: string;
} {
  const element = parseUrl(pathname);

  return {
    project: element[0],
    pos: element[1],
    _locale: element[2] ?? navigator.language.replace('-', '_'),
  };
}

export function getUrlLanguage(pathname: string): string | undefined {
  const element = parseUrl(pathname);

  return element[2];
}

export function handleChangeLanguage(
  location: Location,
  locale: string,
  shopLocale: ShopLocaleModel
) {
  /*
   * This function assumes the pathname is /<project_slug>/<pos_slug>/<locale> or /<project_slug>/<pos_slug>
   * If the path contains more elements, they are preserved.
   */
  const element = parseUrl(location.pathname);
  element[2] = getBestSupportedLocale(locale, shopLocale);

  return '/' + element.join('/') + location.search;
}
