import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Price from '../Price/Price';
import ReactMarkdown from 'react-markdown';
import Translator from '../../services/translator';
import ProductModel from '../../Models/ProductModel';
import './Drawer.css';

interface DrawerHeaderProps {
  onCancel: () => void;
  product: ProductModel;
}

const DrawerHeader = ({ onCancel, product }: DrawerHeaderProps) => (
  <div className="title-modal-container">
    <div className={'drawer-title-container'}>
      <Button type="text" icon={<CloseOutlined />} onClick={onCancel} />
      <div>
        <div className="titleName">{product.name}</div>
        <ReactMarkdown className="titleDesc">
          {product.shortDescription}
        </ReactMarkdown>
      </div>
    </div>
    <div>
      {product.priceLabel !== null ? (
        <div className="apd">{product.priceLabel.label}</div>
      ) : (
        product.priceMin &&
        product.priceMin.amount !== 0 && (
          <div className="apd">
            {Translator.trans('accounting.amount.from')}
          </div>
        )
      )}
      <div>
        <div>
          <Price
            priceDiscount={
              product.isDiscount ? product.priceDiscount.amount : null
            }
            priceMin={
              product.priceMin !== null && product.priceLabel === null
                ? product.priceMin.amount
                : null
            }
            currentPrice={
              product.priceLabel !== null
                ? product.priceLabel.price.amount
                : product.price.amount
            }
            currency={product.price.currency}
          />
        </div>
      </div>
    </div>
  </div>
);

export default DrawerHeader;
