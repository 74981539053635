import SessionModel from './SessionModel';

export type barcodeStatus =
  | 'validated'
  | 'scanned'
  | 'swapped'
  | 'refunded'
  | 'postponed'
  | 'redeemed'
  | 'giveup';

export default class BarcodeModel {
  id: number;
  attendeeDisplayName: string;
  placementUnitLabel: string | null;
  placementEventKey: string | null;
  isDownloadable: boolean;
  isExchangeable: boolean;
  hasCustomForm: boolean;
  signature: string;
  downloadHash: string;
  downloadLink: string;
  status: barcodeStatus;
  forwards: BarcodeForwardModel[];
  item: BarcodeItemModel | null;
  order: {
    id: number;
    signature: string;
  };
  downloadHashGoogle: string; // TODO: implement when received from backend
  downloadHashApple: string; // TODO: implement when received from backend

  constructor(data: any) {
    this.id = data.id;
    this.attendeeDisplayName = data.attendeeDisplayName;
    this.placementUnitLabel = data.placementUnitLabel;
    this.placementEventKey = data.placementEventKey;
    this.isDownloadable = data.isDownloadable;
    this.isExchangeable = data.isExchangeable;
    this.hasCustomForm = data.hasCustomForm;
    this.signature = data.signature;
    this.downloadHash = data.downloadHash;
    this.downloadLink = data.downloadLink;
    this.status = data.status;
    this.forwards = data.forwards
      ? data.forwards.map((forward: any) => new BarcodeForwardModel(forward))
      : [];
    this.item = data.item ? new BarcodeItemModel(data.item) : null;
    this.order = data.order;
    this.downloadHashGoogle = data.downloadHashGoogle;
    this.downloadHashApple = data.downloadHashApple;
  }
}

export type barcodeForwardStatus = 'opened' | 'canceled' | 'closed';
export class BarcodeForwardModel {
  id: number;
  signature: string;
  recipientEmail: string;
  recipientFirstName: string | null;
  recipientLastName: string | null;
  status: barcodeForwardStatus;

  constructor(data: any) {
    this.id = data.id;
    this.signature = data.signature;
    this.recipientEmail = data.recipientEmail;
    this.recipientFirstName = data.recipientFirstName;
    this.recipientLastName = data.recipientLastName;
    this.status = data.status;
  }
}

export class BarcodeItemModel {
  formuleId: number;
  id: number;
  name: string;
  quantity: number;
  session: SessionModel | null;

  constructor(data: any) {
    this.formuleId = data.formuleId;
    this.id = data.id;
    this.name = data.name;
    this.quantity = data.quantity;
    this.session = data.session ? new SessionModel(data.session) : null;
  }
}
