import React, { useEffect, useState } from 'react';
import Container from '../Container/Container';
import SimplePrice from '../Price/SimplePrice';
import Quantity from '../Quantity/Quantity';
import { formatMoney } from '../../services/formatMoney';
import { formatTime } from '../../services/formatDate';
import { DeleteOutlined } from '@ant-design/icons';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import SessionModel from '../../Models/SessionModel';
import ProductModel from '../../Models/ProductModel';
import './Sessions.css';

interface SessionListItemProps {
  item: SessionModel;
  isDeletable: boolean;
  product: ProductModel;
  handleChangeQtySession: (session: SessionModel, quantity: number) => void;
  unitPrice: number;
  totalAllQty: number;
  exchangeQty?: number;
}

const SessionListItem = (props: SessionListItemProps) => {
  const { item, product } = props;
  const [quantityMinPerSale, setQuantityMinPerSale] = useState(0);
  const currency = shopConfig.locale.currencyCode;
  const locale = shopConfig.locale.locale;

  useEffect(() => {
    const quantityMinPerSale = product.quantityMinPerSale
      ? product.quantityMinPerSale
      : 0;

    setQuantityMinPerSale(quantityMinPerSale);
  }, [product.quantityMinPerSale]);

  const handleMin = () => {
    const quantityIncrement = product.quantityIncrement
      ? product.quantityIncrement
      : 1;

    const newQuantity = item.quantity - quantityIncrement;
    props.handleChangeQtySession(item, newQuantity);
  };

  const handlePlus = () => {
    const quantityIncrement = product.quantityIncrement
      ? product.quantityIncrement
      : 1;

    const newQuantity = item.quantity + quantityIncrement;
    props.handleChangeQtySession(item, newQuantity);
  };

  const handleChange = (number) => {
    props.handleChangeQtySession(item, number);
  };

  const handleDelete = () => {
    props.handleChangeQtySession(item, 0);
  };

  return (
    <>
      <div className={'session-list-item-container'}>
        <Container
          className={'session-list-item'}
          disabled={item.qtyRemaining === 0}
        >
          <div>
            <span className={'session-details-hour-start'}>
              {formatTime(item.dateStart)}
            </span>
            <span className={'session-details-hour-split'}>{' - '}</span>
            <span className={'session-details-hour-end'}>
              {formatTime(item.dateStop)}
            </span>
          </div>
          <div>{item.places.join(', ')}</div>
          <div>{item.performers.join(', ')}</div>
          <div>{item.language}</div>
          <div>
            <SimplePrice price={props.unitPrice} currency={currency} />
          </div>
          {item.qtyRemaining && shopConfig.pos.showAvailabilityIndicators ? (
            <div className={'list-item-qty-remaining'}>
              {Translator.trans('product.qtyRemainingTicket.quantity', {
                quantity: item.qtyRemaining,
              })}
            </div>
          ) : null}
          <Quantity
            minQuantity={quantityMinPerSale}
            remainingQuantity={item.qtyRemaining}
            quantityMaxPerSale={product.quantityMaxPerSale}
            handleMin={handleMin}
            currentValue={item.quantity}
            handlePlus={handlePlus}
            handleChange={handleChange}
            disabled={props.totalAllQty === props.exchangeQty}
            isInput
          />
          <div>{formatMoney(currency, locale, item.totalPrice)}</div>
        </Container>

        {props.isDeletable && <DeleteOutlined onClick={handleDelete} />}
      </div>
      <div className={'session-list-item-mobile-container'}>
        <Container
          className={'session-list-item-mobile'}
          disabled={item.qtyRemaining === 0}
        >
          {item.performers && item.performers.length > 0 ? (
            <div className={'session-details-mobile'}>
              <span>{Translator.trans('session.performers')}</span>
              <span>{item.performers.join(', ')}</span>
            </div>
          ) : null}
          {item.language && item.language !== '' ? (
            <div className={'session-details-mobile'}>
              <span>{Translator.trans('session.language')}</span>
              <span>{item.language}</span>
            </div>
          ) : null}
          {item.places && item.places.length > 0 ? (
            <div className={'session-details-mobile'}>
              <span>{Translator.trans('session.places')}</span>
              <span>{item.places.join(', ')}</span>
            </div>
          ) : null}
          <div className={'session-details-mobile'}>
            <span className={'session-hours-mobile'}>
              {Translator.trans('session.hours')}
            </span>
            <span className={'session-details-hour-start'}>
              {formatTime(item.dateStart)}
            </span>
            <span className={'session-details-hour-split'}>{' - '}</span>
            <span className={'session-details-hour-end'}>
              {formatTime(item.dateStop)}
            </span>
          </div>
          <div className={'session-details-mobile'}>
            <div className={'session-details-price-mobile'}>
              <SimplePrice price={props.unitPrice} currency={currency} />
            </div>
            <div className={'session-details-quantity-mobile'}>
              <Quantity
                minQuantity={quantityMinPerSale}
                remainingQuantity={item.qtyRemaining}
                quantityMaxPerSale={product.quantityMaxPerSale}
                handleMin={handleMin}
                currentValue={item.quantity}
                handlePlus={handlePlus}
                handleChange={handleChange}
                isInput
              />
            </div>
          </div>
        </Container>
        {props.isDeletable && <DeleteOutlined onClick={handleDelete} />}
      </div>
    </>
  );
};

export default SessionListItem;
