import { barcodesConstants } from '../constants/barcodes.constants';

type barcodeParams = {
  id: number;
  signature: string;
};

export const getBarcodesListRequest = (params?: any) => ({
  type: barcodesConstants.GET_BARCODES_LIST_REQUEST,
  params,
});

export const getBarcodesListSuccess = (response: any) => ({
  type: barcodesConstants.GET_BARCODES_LIST_SUCCESS,
  response,
});

export const getBarcodesListFailure = (error: any) => ({
  type: barcodesConstants.GET_BARCODES_LIST_FAILURE,
  error,
});

export const getExchangeBarcodesListRequest = (params: barcodeParams) => ({
  type: barcodesConstants.GET_EXCHANGE_BARCODES_REQUEST,
  params,
});

export const getExchangeBarcodesListSuccess = (response: any) => ({
  type: barcodesConstants.GET_EXCHANGE_BARCODES_SUCCESS,
  response,
});

export const getExchangeBarcodesListFailure = (error: any) => ({
  type: barcodesConstants.GET_EXCHANGE_BARCODES_FAILURE,
  error,
});

export const postExchangeBarcodeRequest = (
  barcode: any,
  params: barcodeParams
) => ({
  type: barcodesConstants.POST_EXCHANGE_BARCODE_REQUEST,
  barcode,
  params,
});

export const postExchangeBarcodeSuccess = (response: any) => ({
  type: barcodesConstants.POST_EXCHANGE_BARCODE_SUCCESS,
  response,
});

export const postExchangeBarcodeFailure = (error: any) => ({
  type: barcodesConstants.POST_EXCHANGE_BARCODE_FAILURE,
  error,
});

export const forwardBarcodeRequest = (values: any, params: barcodeParams) => ({
  type: barcodesConstants.POST_FORWARD_BARCODE_REQUEST,
  values,
  params,
});

export const forwardBarcodeSuccess = (response: any) => ({
  type: barcodesConstants.POST_FORWARD_BARCODE_SUCCESS,
  response,
});

export const forwardBarcodeFailure = (error: any) => ({
  type: barcodesConstants.POST_FORWARD_BARCODE_FAILURE,
  error,
});

export const getBarcodeAttendeeRequest = (barcode: any) => ({
  type: barcodesConstants.GET_BARCODE_ATTENDEE_REQUEST,
  barcode,
});

export const getBarcodeAttendeeSuccess = (response: any) => ({
  type: barcodesConstants.GET_BARCODE_ATTENDEE_SUCCESS,
  response,
});

export const getBarcodeAttendeeFailure = (error: any) => ({
  type: barcodesConstants.GET_BARCODE_ATTENDEE_FAILURE,
  error,
});

export const postBarcodeAttendeeRequest = (payload: any, params: any) => ({
  type: barcodesConstants.POST_BARCODE_ATTENDEE_REQUEST,
  payload,
  params,
});

export const postBarcodeAttendeeSuccess = (response: any) => ({
  type: barcodesConstants.POST_BARCODE_ATTENDEE_SUCCESS,
  response,
});

export const postBarcodeAttendeeFailure = (error: any) => ({
  type: barcodesConstants.POST_BARCODE_ATTENDEE_FAILURE,
  error,
});

export const clearBarcodes = () => ({
  type: barcodesConstants.CLEAR_EXCHANGE_BARCODES,
});
