import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Result, Spin } from 'antd';
import Container from '../../../components/Container/Container';
import Title from '../../../components/Title/Title';
import EmisysButton from '../../../components/EmisysButton/EmisysButton';
import { Type } from '../../../components/Form/FormType';
import {
  getCustomerFormRequest,
  postCustomerFormRequest,
} from '../../../redux/actions/customer.actions';
import { checkReduxResponse } from '../../../services/httpService';
import { validateFormMessages } from '../../../services/validateFormMessage';
import Translator from '../../../services/translator';
import { openNotificationError } from '../../../components/Notification/Notification';
import '../index.css';

class CustomerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getLoading: false,
      postLoading: false,
      errorMessage: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (!this.props.form) {
      this.props.getCustomerForm();
    }
    this.setState({ errorMessage: '' });
  }

  componentDidUpdate(prevProps) {
    if (this.props.customerForm !== prevProps.customerForm) {
      this.setState({ getLoading: this.props.customerForm.loading });
      if (checkReduxResponse(this.props.customerForm, 'form')) {
        this.props.updateCustomerForm(this.props.customerForm.form);
        this.initiateForm(this.props.customerForm.form.questions);
      }

      if (this.props.customerForm.error) {
        if (this.props.customerForm.error.status !== 401) {
          this.setState({
            errorMessage: this.props.customerForm.error.message,
          });
        }
      }
    }

    if (this.props.newCustomerFrom !== prevProps.newCustomerFrom) {
      this.setState({ postLoading: this.props.newCustomerFrom.loading });
      if (checkReduxResponse(this.props.newCustomerFrom, 'form')) {
        this.props.goToNextStep();
      }

      if (this.props.newCustomerFrom.error) {
        if (this.props.newCustomerFrom.error.message) {
          openNotificationError(
            Translator.trans('error.internal_error.title'),
            this.props.newCustomerFrom.error.message
          );
        }

        if (this.props.newCustomerFrom.error.errors) {
          this.handleDisplayErrors(this.props.newCustomerFrom.error.errors);
        }
      }
    }
  }

  initiateForm = (formQuestions) => {
    let newCustomerInfo = {};
    formQuestions.forEach((question) => {
      if (
        question.titleLevel !== 'title' &&
        question.titleLevel !== 'subtitle'
      ) {
        newCustomerInfo[question.id] = question.value;
      }
    });

    this.props.updateCustomerInfo(newCustomerInfo);
  };

  handleCustomerForm = (event, question) => {
    const newCustomerInfo = { ...this.props.customerInfo };
    newCustomerInfo[question] = event;

    this.props.updateCustomerInfo(newCustomerInfo);
  };

  handleDisplayErrors = (errors) => {
    let setErrors = [];

    errors.forEach((error) => {
      let matches = (error.fieldName.match(/\[.+?]/g) || []).map((str) =>
        str.slice(1, -1)
      );

      if (matches.length > 0) {
        setErrors.push({
          name: matches.join('_'),
          errors: [error.message],
        });
      }
    });

    if (setErrors.length > 0) {
      this.formRef.current?.setFields(setErrors);
      this.formRef.current?.scrollToField(setErrors[0].name);
    }
  };

  handleRemoveErrors = (obj) => {
    let errors = [];

    Object.keys(obj).map((key) =>
      errors.push({
        name: key,
        errors: '',
      })
    );

    this.formRef.current?.setFields(errors);
  };

  onFinish = () => {
    const newCustomerInfo = { ...this.props.customerInfo };
    const payload = {};
    Object.keys(newCustomerInfo).forEach((key) => {
      const newKey = key.substring(key.indexOf('#') + 1);
      payload[newKey] = newCustomerInfo[key];
    });

    this.handleRemoveErrors(this.formRef.current?.getFieldsValue());

    this.props.postCustomerForm(payload);
  };

  render() {
    const { customerInfo, form } = this.props;
    const { getLoading, postLoading, errorMessage } = this.state;

    return (
      <Col className="gutter-row" xs={24}>
        <Spin spinning={getLoading}>
          {customerInfo ? (
            <Form
              ref={this.formRef}
              name="customerForm"
              onFinish={this.onFinish}
              initialValues={customerInfo}
              scrollToFirstError
              validateMessages={validateFormMessages}
            >
              <Container>
                <Title value={Translator.trans('navbar.information')} />

                {customerInfo &&
                  form?.questions.map((question, index) => (
                    <Type
                      key={index}
                      uniqueId={question.id}
                      saleKey={''}
                      question={question}
                      value={customerInfo[question.id]}
                      onChange={this.handleCustomerForm}
                    />
                  ))}

                <EmisysButton
                  className={'basket-review-next'}
                  loading={postLoading}
                  htmlType="submit"
                >
                  {Translator.trans('navbar.next')}
                </EmisysButton>
              </Container>
            </Form>
          ) : (
            <Container className={'empty-form-container'}>
              <Title value={Translator.trans('navbar.information')} />

              {errorMessage && <Result status="404" title={errorMessage} />}
            </Container>
          )}
        </Spin>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  customerForm: state.customer.customerForm,
  newCustomerFrom: state.customer.newCustomerFrom,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerForm: () => dispatch(getCustomerFormRequest()),
  postCustomerForm: (form) => dispatch(postCustomerFormRequest(form)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
