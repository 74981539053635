import React, { useState } from 'react';
import BarcodeModel from '../../../Models/BarcodeModel';
import EmisysButton from '../../../components/EmisysButton/EmisysButton';
import {
  EditOutlined,
  FilePdfOutlined,
  MoreOutlined,
  SendOutlined,
  SwapOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import Translator from '../../../services/translator';
import { formatDateTimeToString } from '../../../services/formatDate';
import { shopConfig } from '../../../Globals/ShopConfig';
import { Alert, Button, Dropdown, MenuProps, Tooltip } from 'antd';
import ModalTransfer from '../../../components/Modal/ModalTransfer';
import ModalExchange from '../../../components/Modal/ModalExchange';
import ModalUpdateUser from '../../../components/Modal/ModalUpdateUser';
import getBarcodeStatus from './getBarcodeStatus';
import ModalTransferList from '../../../components/Modal/ModalTransferList';
import WalletApple from '../../../components/WalletApple/WalletApple';
import WalletGoogle from '../../../components/WalletGoogle/WalletGoogle';
import { createBarcodeDownloadUrl } from '../../../services/createBarcodeDownloadUrl';
import { pagesConstant } from '../../../Constants/Constants';
import ModalPlacement from '../../../components/Modal/ModalPlacement';
import history from '../../../services/history';
import '../index.css';

interface OrderDetailItemInterface {
  barcode: BarcodeModel;
  refreshList: () => void;
}

const UserBarcodeItem = (props: OrderDetailItemInterface) => {
  const [isTransferOpen, setIsTransferOpen] = useState(false);
  const [isExchangeOpen, setIsExchangeOpen] = useState(false);
  const [isUpdateUserOpen, setIsUpdateUserOpen] = useState(false);
  const [isForwardList, setIsForwardList] = useState(false);
  const [isPlacementOpen, setIsPlacementOpen] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: 0,
      label: (
        <Tooltip
          placement="left"
          title={
            props.barcode.status !== 'validated'
              ? Translator.trans('barcode.disabled.actions')
              : ''
          }
        >
          <span>{Translator.trans('word.action.transfer')}</span>
        </Tooltip>
      ),
      onClick: () => setIsTransferOpen(true),
      icon: <SendOutlined />,
      disabled: props.barcode.status !== 'validated',
    },
    { type: 'divider' },
    {
      key: 1,
      label: (
        <Tooltip
          placement="left"
          title={
            props.barcode.status !== 'validated'
              ? Translator.trans('barcode.disabled.actions')
              : ''
          }
        >
          <span>{Translator.trans('word.action.exchange')}</span>
        </Tooltip>
      ),
      onClick: () => setIsExchangeOpen(true),
      icon: <SwapOutlined />,
      disabled:
        props.barcode.isExchangeable === false ||
        props.barcode.status !== 'validated',
    },
    { type: 'divider' },
    {
      key: 2,
      label: (
        <Tooltip
          placement="left"
          title={
            props.barcode.status !== 'validated'
              ? Translator.trans('barcode.disabled.actions')
              : ''
          }
        >
          <span>{Translator.trans('barcode.change.user')}</span>
        </Tooltip>
      ),
      onClick: () => setIsUpdateUserOpen(true),
      icon: <EditOutlined />,
      disabled:
        props.barcode.hasCustomForm === false ||
        props.barcode.status !== 'validated',
    },
    { type: 'divider' },
    {
      key: 3,
      label: <span>{Translator.trans('barcode.see.order')}</span>,
      onClick: () =>
        history.navigate(
          pagesConstant.order.detail +
            props.barcode.order.id +
            '/' +
            props.barcode.order.signature
        ),
      icon: <FileSearchOutlined />,
    },
  ];

  const forwardSuccessBarcode = props.barcode.forwards.find(
    (forward) => forward.status === 'closed'
  );

  const forwardWaitingBarcode = props.barcode.forwards.find(
    (forward) => forward.status === 'opened'
  );

  const isAppleDevice = () => {
    // Fallback to `navigator.userAgent` for older browsers
    const userAgent = navigator.userAgent || '';
    return /iPhone|iPad|iPod|Mac/.test(userAgent);
  };

  return (
    <>
      <div className={`user-ticket-item-container`}>
        <div className={'user-ticket-item-info'}>
          <div
            className={`user-ticket-item-left ${
              forwardSuccessBarcode && 'disabled'
            }`}
          >
            <div className={'user-ticket-item-title'}>
              <h2>{props.barcode.item.name} </h2>
              {getBarcodeStatus(props.barcode.status)}
            </div>
            {!forwardWaitingBarcode && (
              <ul className={'user-ticket-item-list'}>
                <li>{props.barcode.attendeeDisplayName}</li>
                {props.barcode.item.session && (
                  <>
                    <li>
                      {formatDateTimeToString(
                        props.barcode.item.session.dateStart
                      )}
                    </li>
                    {props.barcode.item.session.language && (
                      <li>{props.barcode.item.session.language}</li>
                    )}

                    {props.barcode.item.session.places.length > 0 && (
                      <li>{props.barcode.item.session.places.join(', ')}</li>
                    )}

                    {props.barcode.item.session.performers.length > 0 && (
                      <li>
                        {props.barcode.item.session.performers.join(', ')}
                      </li>
                    )}
                  </>
                )}
                {props.barcode.placementUnitLabel && (
                  <li>
                    {Translator.trans('ticket.placement', {
                      seat: props.barcode.placementUnitLabel,
                    })}
                  </li>
                )}
              </ul>
            )}
            {props.barcode.placementUnitLabel &&
              props.barcode.placementEventKey && (
                <div
                  className={'see-barcode-placement'}
                  onClick={() => setIsPlacementOpen(true)}
                >
                  {Translator.trans('barcode.see.placement')}
                </div>
              )}
          </div>

          <div className={'user-ticket-item-right'}>
            {forwardSuccessBarcode ? (
              <div className={'user-ticket-item-transferred'}>
                {Translator.transHtml('barcode.success.transferred', {
                  email: forwardSuccessBarcode.recipientEmail,
                })}
              </div>
            ) : (
              props.barcode.status !== 'swapped' &&
              props.barcode.status !== 'refunded' &&
              (props.barcode.isDownloadable ||
                props.barcode.isExchangeable ||
                props.barcode.hasCustomForm) && (
                <div className={'user-ticket-item-download'}>
                  {props.barcode.isDownloadable && (
                    <EmisysButton
                      href={createBarcodeDownloadUrl(
                        shopConfig.router.barcodeDownloadPdfUrl,
                        props.barcode
                      )}
                      icon={<FilePdfOutlined />}
                    >
                      {Translator.trans('barcode.download')}
                    </EmisysButton>
                  )}

                  {isAppleDevice() &&
                    shopConfig.router.barcodeDownloadApplePassUrl && (
                      <a
                        className={'wallet-btn'}
                        href={createBarcodeDownloadUrl(
                          shopConfig.router.barcodeDownloadApplePassUrl,
                          props.barcode
                        )}
                      >
                        <WalletApple locale={shopConfig.locale.locale} />
                      </a>
                    )}
                  {!isAppleDevice() &&
                    shopConfig.router.barcodeDownloadGooglePassUrl && (
                      <a
                        className={'wallet-btn'}
                        href={createBarcodeDownloadUrl(
                          shopConfig.router.barcodeDownloadGooglePassUrl,
                          props.barcode
                        )}
                      >
                        <WalletGoogle locale={shopConfig.locale.locale} />
                      </a>
                    )}

                  <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <Button className={'user-ticket-item-action'}>
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </div>
              )
            )}
          </div>
        </div>

        {forwardWaitingBarcode && (
          <Alert
            key={forwardWaitingBarcode.id}
            className={'forward-alert'}
            message={Translator.trans('barcode.wait.transfer.title')}
            description={Translator.transHtml('barcode.wait.transfer.info', {
              email: forwardWaitingBarcode.recipientEmail,
            })}
            type="warning"
            action={
              props.barcode.forwards.length > 1 && (
                <Button size="small" onClick={() => setIsForwardList(true)}>
                  {Translator.trans('barcode.multi.forwarded')}
                </Button>
              )
            }
          />
        )}
      </div>

      {props.barcode.forwards.length > 1 && (
        <ModalTransferList
          isOpen={isForwardList}
          handleClose={() => setIsForwardList(false)}
          forwardList={props.barcode.forwards}
        />
      )}

      {isUpdateUserOpen && (
        <ModalUpdateUser
          isOpen={isUpdateUserOpen}
          handleClose={() => setIsUpdateUserOpen(false)}
          barcode={props.barcode}
          refreshList={props.refreshList}
        />
      )}

      {isTransferOpen && (
        <ModalTransfer
          isOpen={isTransferOpen}
          handleClose={() => setIsTransferOpen(false)}
          barcode={props.barcode}
          refreshList={props.refreshList}
        />
      )}

      {isExchangeOpen && (
        <ModalExchange
          isOpen={isExchangeOpen}
          handleClose={() => setIsExchangeOpen(false)}
          item={props.barcode.item}
          barcode={props.barcode}
          refreshList={props.refreshList}
        />
      )}

      {isPlacementOpen && (
        <ModalPlacement
          isOpen={isPlacementOpen}
          handleClose={() => setIsPlacementOpen(false)}
          barcode={props.barcode}
        />
      )}
    </>
  );
};

export default UserBarcodeItem;
