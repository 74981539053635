import React, { useCallback, useEffect, useRef, useState } from 'react';
import ModalEmisys from './ModalEmisys';
import { Skeleton, Spin } from 'antd';
import Title from '../Title/Title';
import Translator from '../../services/translator';
import ExchangeProductItem from '../ExchangeProductItem/ExchangeProductItem';
import { useDispatch, useSelector } from 'react-redux';
import ProductModel from '../../Models/ProductModel';
import { getDatesListRequest } from '../../redux/actions/dates.actions';
import {
  clearBarcodes,
  getExchangeBarcodesListRequest,
} from '../../redux/actions/barcodes.actions';
import { checkReduxResponse } from '../../services/httpService';
import BarcodeModel, { BarcodeItemModel } from '../../Models/BarcodeModel';
import { openNotificationError } from '../Notification/Notification';
import { storeType } from '../../index';
import SuccessErrorIcon from '../SuccessErrorIcon/SuccessErrorIcon';
import EmisysButton from '../EmisysButton/EmisysButton';
import './Modal.css';

interface ModalFilterDateProps {
  isOpen: boolean;
  handleClose: () => void;
  item: BarcodeItemModel;
  barcode: BarcodeModel;
  refreshList: () => void;
}

const ModalExchange = (props: ModalFilterDateProps) => {
  const dispatch = useDispatch();
  let timeout = useRef(null);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exchangeProducts, setExchangeProducts] = useState<ProductModel[]>([]);
  const [currentProduct, setCurrentProduct] = useState<ProductModel | null>(
    null
  );
  const [successMsg, setSuccessMsg] = useState('');

  const newExchangeBarcode = useSelector(
    (state: storeType) => state.barcodes.newExchangeBarcode
  );
  const exchangeBarcodesList = useSelector(
    (state: storeType) => state.barcodes.exchangeBarcodesList
  );

  const getDates = useCallback(() => {
    dispatch(getDatesListRequest());
  }, [dispatch]);

  const getExchangeBarcodesList = useCallback(() => {
    dispatch(
      getExchangeBarcodesListRequest({
        id: props.barcode.id,
        signature: props.barcode.signature,
      })
    );
  }, [dispatch, props.barcode]);

  useEffect(() => {
    getDates();
    getExchangeBarcodesList();
  }, [getDates, getExchangeBarcodesList]);

  const showSuccessMessage = useCallback(() => {}, []);

  const closeAfterSuccess = useCallback(() => {
    props.handleClose();
    clearTimeout(timeout.current);
  }, [props]);

  useEffect(() => {
    if (newExchangeBarcode) {
      setLoadingSpin(newExchangeBarcode.loading);
      if (checkReduxResponse(newExchangeBarcode, 'barcode')) {
        setSuccessMsg(Translator.trans('exchange.success.message'));
        props.refreshList();

        timeout.current = setTimeout(() => {
          closeAfterSuccess();
        }, 7000);
      }

      if (newExchangeBarcode.error) {
        openNotificationError(newExchangeBarcode.error.message, '');
      }

      dispatch(clearBarcodes());
    }
  }, [
    closeAfterSuccess,
    dispatch,
    newExchangeBarcode,
    props,
    showSuccessMessage,
  ]);

  useEffect(() => {
    if (exchangeBarcodesList) {
      setLoading(exchangeBarcodesList.loading);
      if (checkReduxResponse(exchangeBarcodesList, 'barcodes')) {
        let newProducts: ProductModel[] = [];
        exchangeBarcodesList.barcodes.forEach((product: ProductModel) => {
          if (product.id === props.item.formuleId) {
            setCurrentProduct(product);
          } else {
            newProducts.push(product);
          }
        });

        setExchangeProducts(newProducts);
      }
    }
  }, [props.item.formuleId, exchangeBarcodesList]);

  return (
    <ModalEmisys
      closable={false}
      open={props.isOpen}
      onCancel={props.handleClose}
      footer={null}
      centered
      width={successMsg ? 'small' : 'large'}
    >
      <div className={'simple-modal'}>
        <Spin spinning={loadingSpin}>
          {successMsg === '' ? (
            <>
              <Title
                value={Translator.trans('exchange.title.current_product')}
              />
              <Skeleton loading={loading} active>
                <div>
                  {currentProduct !== null ? (
                    <ExchangeProductItem
                      product={currentProduct}
                      barcode={props.barcode}
                      oldProduct={currentProduct}
                      qty={props.item.quantity}
                      isCurrentProduct
                    />
                  ) : (
                    <div className="empty-exchange-products">
                      {Translator.trans('exchange.error.emptyExchange')}
                    </div>
                  )}
                </div>

                {exchangeProducts.length !== 0 ? (
                  <div className={'other-products-container'}>
                    <Title
                      value={Translator.trans('exchange.title.other_products', {
                        quantity: exchangeProducts.length,
                      })}
                    />
                    <div>
                      {exchangeProducts.map((product: ProductModel) => (
                        <ExchangeProductItem
                          key={product.id}
                          product={product}
                          barcode={props.barcode}
                          oldProduct={currentProduct}
                          qty={props.item.quantity}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  currentProduct !== null &&
                  !currentProduct.hasOptions &&
                  !currentProduct.hasSessions && (
                    <>
                      <Title
                        value={Translator.trans(
                          'exchange.title.other_products',
                          {
                            quantity: exchangeProducts.length,
                          }
                        )}
                      />
                      <div className="empty-exchange-products">
                        {Translator.trans('exchange.error.emptyExchange')}
                      </div>
                    </>
                  )
                )}
              </Skeleton>
            </>
          ) : (
            <>
              <h2>{successMsg}</h2>

              <SuccessErrorIcon success />

              <EmisysButton onClick={closeAfterSuccess}>
                {Translator.trans('word.action.close')}
              </EmisysButton>
            </>
          )}
        </Spin>
      </div>
    </ModalEmisys>
  );
};

export default ModalExchange;
