import { accommodationConstants } from '../constants/accommodation.constants';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  occupantTypesList: null,
  accommodationList: null,
  assembliesList: null,
  accommodationDetail: null,
};

const accommodation = (state = initialState, action: any) => {
  switch (action.type) {
    case accommodationConstants.GET_OCCUPANT_TYPES_REQUEST: {
      return {
        ...state,
        occupantTypesList: { occupantTypes: [], error: null, loading: true },
      };
    }

    case accommodationConstants.GET_OCCUPANT_TYPES_SUCCESS: {
      return {
        ...state,
        occupantTypesList: {
          occupantTypes: action.occupantTypes,
          error: null,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_OCCUPANT_TYPES_FAILURE: {
      return {
        ...state,
        occupantTypesList: {
          occupantTypes: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_LIST_REQUEST: {
      return {
        ...state,
        accommodationList: { accommodations: [], error: null, loading: true },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_LIST_SUCCESS: {
      return {
        ...state,
        accommodationList: {
          accommodations: action.accommodationList,
          error: null,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_LIST_FAILURE: {
      return {
        ...state,
        accommodationList: {
          accommodations: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_REQUEST: {
      return {
        ...state,
        assembliesList: { list: [], error: null, loading: true },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_SUCCESS: {
      return {
        ...state,
        assembliesList: {
          list: action.assembliesList,
          error: null,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_ASSEMBLIES_FAILURE: {
      return {
        ...state,
        assembliesList: {
          list: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_DETAIL_REQUEST: {
      return {
        ...state,
        accommodationDetail: { accommodation: [], error: null, loading: true },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_DETAIL_SUCCESS: {
      return {
        ...state,
        accommodationDetail: {
          accommodation: action.accommodation,
          error: null,
          loading: false,
        },
      };
    }

    case accommodationConstants.GET_ACCOMMODATION_DETAIL_FAILURE: {
      return {
        ...state,
        accommodationDetail: {
          accommodation: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default accommodation;
