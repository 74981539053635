import React from 'react';
import { Radio } from 'antd';
import ReactMarkdown from 'react-markdown';
import { RenderingProps } from '../TicketOptions';
import '../MultipleChoice.css';

const ButtonRendering = (props: RenderingProps) => (
  <>
    <div className="multipleChoiceTitle">{props.option}</div>
    <div>
      <Radio.Group
        className="fullRadio option-button-container"
        onChange={(event) =>
          props.getRadioValue(
            props.option,
            props.currentStep,
            event.target.value
          )
        }
        value={
          props.radioValue[props.currentStep]
            ? props.radioValue[props.currentStep][props.option]
            : null
        }
      >
        {props.values.map((optionValue) => (
          <Radio.Button
            key={optionValue.id}
            value={optionValue.name}
            onClick={() => {
              props.getOption(optionValue, props.currentStep + 1);
              props.getOptionDesc(optionValue.description, props.currentStep);
            }}
          >
            <div>{optionValue.name}</div>
          </Radio.Button>
        ))}
      </Radio.Group>
      <div className="description">
        <ReactMarkdown>{props.optionArray[props.currentStep]}</ReactMarkdown>
      </div>
    </div>
  </>
);

export default ButtonRendering;
