import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentMethodRequest } from '../../redux/actions/paymentMethod.actions';
import { checkReduxResponse } from '../../services/httpService';
import Translator from '../../services/translator';
import PaymentMethodModel from '../../Models/OrderOverview/PaymentMethodModel';
import { storeType } from '../../index';
import './PaymentMethod.css';

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const [currentList, setCurrentList] = useState([]);

  const paymentMethodList = useSelector(
    (state: storeType) => state.paymentMethod.paymentMethodList
  );

  const getPaymentMethod = useCallback(() => {
    dispatch(getPaymentMethodRequest());
  }, [dispatch]);

  useEffect(() => {
    if (paymentMethodList) {
      if (checkReduxResponse(paymentMethodList, 'paymentMethod')) {
        setCurrentList(paymentMethodList.paymentMethod);
      }
    } else {
      getPaymentMethod();
    }
  }, [getPaymentMethod, paymentMethodList]);

  return (
    <div className="paymentMethod-container">
      {currentList.length !== 0 && (
        <>
          <div className="paymentMethod-title">
            {Translator.trans('payment.available_payment_methods')}
          </div>
          <div className="paymentMethod">
            {currentList.map((item: PaymentMethodModel) => (
              <img key={item.id} src={item.picture} alt={item.name} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentMethod;
