import React from 'react';
import {
  LogoutOutlined,
  // UserOutlined,
  FileSearchOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { pagesConstant, shopConstants } from '../../../Constants/Constants';
import Translator from '../../../services/translator';
import history from '../../../services/history';
import { addPreviousPathStorage } from '../../../services/handleStorage';
import { Link } from 'react-router-dom';
import { userProfileType } from '../Header';
import './index.css';

const userDropdown = (userProfile: userProfileType, onLogOut: () => void) => {
  const goToSignIn = () => {
    addPreviousPathStorage();
    history.navigate(pagesConstant.auth + shopConstants.SIGNIN);
  };

  const goToSignUp = () => {
    addPreviousPathStorage();
    history.navigate(pagesConstant.auth + shopConstants.SIGNUP);
  };

  return userProfile
    ? [
        // { TODO: Uncomment this block for the profile page
        //   key: 'profile',
        //   label: (
        //     <Link to={pagesConstant.account.home}>
        //       <div className={'menu-btn'}>
        //         <UserOutlined />
        //         <span>{Translator.trans('account.profile.my_account')}</span>
        //       </div>
        //     </Link>
        //   ),
        // },
        {
          key: 'tickets',
          label: (
            <Link to={pagesConstant.account.tickets}>
              <div className={'menu-btn'}>
                <FileSearchOutlined />
                <span>{Translator.trans('page.titles.account.tickets')}</span>
              </div>
            </Link>
          ),
        },
        {
          key: 'orders',
          label: (
            <Link to={pagesConstant.order.list}>
              <div className={'menu-btn'}>
                <HistoryOutlined />
                <span>{Translator.trans('page.titles.orders')}</span>
              </div>
            </Link>
          ),
        },
        {
          key: 'logout',
          label: (
            <div className={'menu-btn menu-logout-btn'} onClick={onLogOut}>
              <LogoutOutlined />
              <span>{Translator.trans('auth.action.logout')}</span>
            </div>
          ),
        },
      ]
    : [
        {
          key: 'login',
          label: (
            <div onClick={goToSignIn}>
              {Translator.trans('auth.action.signIn')}
            </div>
          ),
        },
        {
          key: 'register',
          label: (
            <div onClick={goToSignUp}>
              {Translator.trans('auth.action.signUp')}
            </div>
          ),
        },
      ];
};

export default userDropdown;
