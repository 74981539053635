import { sessionsConstants } from '../constants/sessions.constants';
import SessionModel from '../../Models/SessionModel';
import {
  createDateFilterSession,
  createFilterSession,
} from '../../services/services';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  sessionsList: null,
};

const sessions = (state = initialState, action: any) => {
  switch (action.type) {
    case sessionsConstants.GET_SESSIONS_REQUEST: {
      return {
        ...state,
        sessionsList: {
          sessions: [],
          error: null,
          loading: true,
        },
        dateSession: null,
        filterSession: null,
      };
    }

    case sessionsConstants.GET_SESSIONS_SUCCESS: {
      const sessions = SessionModel.setSessionModel(action.sessions);

      return {
        ...state,
        sessionsList: {
          sessions: sessions,
          error: null,
          loading: false,
        },
        dateSession: createDateFilterSession(sessions),
        filterSession: createFilterSession(sessions),
      };
    }

    case sessionsConstants.GET_SESSIONS_FAILURE: {
      return {
        ...state,
        sessionsList: {
          sessions: [],
          error: new ErrorModel(action.error),
          loading: false,
        },
        dateSession: null,
        filterSession: null,
      };
    }
    default:
      return state;
  }
};

export default sessions;
