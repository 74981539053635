import { sectionsConstants } from '../constants/sections.constants';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { current: any; sectionsList: reducerItemType } = {
  current: null,
  sectionsList: null,
};

const sections = (state = initialState, action: any) => {
  switch (action.type) {
    case sectionsConstants.SET_CURRENT_SECTION: {
      return {
        ...state,
        current: action.section,
      };
    }

    case sectionsConstants.GET_SECTIONS_REQUEST: {
      return {
        ...state,
        sectionsList: { sections: [], error: null, loading: true },
      };
    }

    case sectionsConstants.GET_SECTIONS_SUCCESS: {
      return {
        ...state,
        sectionsList: {
          sections: action.sections,
          error: null,
          loading: false,
        },
      };
    }

    case sectionsConstants.GET_SECTIONS_FAILURE: {
      return {
        ...state,
        sectionsList: {
          sections: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default sections;
