import { upsellingConstants } from '../constants/upselling.constants';
import UpsellingModel from '../../Models/UpsellingModel';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  currentUpselling: null,
};

const upselling = (state = initialState, action: any) => {
  switch (action.type) {
    case upsellingConstants.GET_UPSELLING_REQUEST: {
      return {
        ...state,
        currentUpselling: { upselling: null, error: null, loading: true },
      };
    }

    case upsellingConstants.GET_UPSELLING_SUCCESS: {
      return {
        ...state,
        currentUpselling: {
          upselling: action.upselling.map(
            (item: any) => new UpsellingModel(item)
          ),
          error: null,
          loading: false,
        },
      };
    }

    case upsellingConstants.GET_UPSELLING_FAILURE: {
      return {
        ...state,
        currentUpselling: {
          upselling: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default upselling;
