import React from 'react';
import GoogleWallet_US from '../../assets/images/GoogleWallets/GoogleWallet_US.svg';
import GoogleWallet_NL from '../../assets/images/GoogleWallets/GoogleWallet_NL.svg';
import GoogleWallet_FR from '../../assets/images/GoogleWallets/GoogleWallet_FR.svg';

const WalletGoogle = ({ locale }: { locale: string }) => {
  switch (locale) {
    case 'nl-NL':
      return <img src={GoogleWallet_NL} alt={'Toevoegen aan Google Wallet'} />;
    case 'fr-FR':
      return <img src={GoogleWallet_FR} alt={'Ajouter au google wallet'} />;
    case 'en-US':
    default:
      return <img src={GoogleWallet_US} alt={'Add to Apple Wallet'} />;
  }
};

export default WalletGoogle;
