import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { productIcon } from './Icons';
import { shopConfig } from '../../Globals/ShopConfig';
import { ProductTypeConstant } from '../../Constants/Constants';
import ProductModel from '../../Models/ProductModel';
import './ProductItemIcon.css';

export const showIcon = (product: ProductModel) => {
  const locale = shopConfig.locale.locale;
  let getShortMonth = new Intl.DateTimeFormat(locale.replace('_', '-'), {
    month: 'short',
  });

  let getShortDayMonth = new Intl.DateTimeFormat(locale.replace('_', '-'), {
    day: 'numeric',
    month: 'short',
  });

  const entryDateStart = product.entryDateStart;
  const entryDateStop = product.entryDateStop;
  const isInSameMonth =
    entryDateStart !== null && entryDateStop !== null
      ? entryDateStart.getMonth() < entryDateStop.getMonth()
      : false;

  return (
    <div className="product-icon">
      {product.isOneDay &&
        product.type !== ProductTypeConstant.topup &&
        entryDateStart !== null && (
          <div className="product-icon-dates">
            <span>{entryDateStart.getDate()}</span>
            <span>{getShortMonth.format(entryDateStart)}</span>
          </div>
        )}

      {product.isMultipleDay &&
        product.type !== ProductTypeConstant.topup &&
        entryDateStart !== null &&
        entryDateStop !== null &&
        !isInSameMonth && (
          <div className="product-icon-dates">
            <div className="dates-container">
              <span>{entryDateStart.getDate()}</span>
              <CaretRightOutlined className="between-dates-icon" />
              <span>{entryDateStop.getDate()}</span>
            </div>
            <span>{getShortMonth.format(entryDateStart)}</span>
          </div>
        )}

      {product.isMultipleDay &&
        product.type !== ProductTypeConstant.topup &&
        entryDateStart !== null &&
        entryDateStop !== null &&
        isInSameMonth && (
          <div className="product-icon-dates">
            <div className="dates-container-different-months">
              <span>{getShortDayMonth.format(entryDateStart)}</span>
              <CaretRightOutlined className="between-dates-icon" />
              <span>{getShortDayMonth.format(entryDateStop)}</span>
            </div>
          </div>
        )}

      {product.type === ProductTypeConstant.topup && (
        <div className="product-icon-dates">
          <span>{product.cashlessValue.amount}</span>
          <span>{product.cashlessValue.currency}</span>
        </div>
      )}

      {!product.isMultipleDay &&
        !product.isOneDay &&
        product.type !== ProductTypeConstant.topup &&
        productIcon(product.type)}
    </div>
  );
};

export const ProductItemIcon = ({
  product,
  shopShowFormulePicture,
}: {
  product: ProductModel;
  shopShowFormulePicture: boolean;
}) => (
  <div className="product-icon-container">
    {!shopShowFormulePicture ? (
      <div className="icon-container">{showIcon(product)}</div>
    ) : product.picture ? (
      <div className="images-container">
        <img src={product.picture} alt="productItem" />
      </div>
    ) : (
      <div className="icon-container">{showIcon(product)}</div>
    )}
  </div>
);

export default ProductItemIcon;
