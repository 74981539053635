import { combineReducers } from 'redux';
import tickets from './tickets.reducer';
import options from './options.reducer';
import basket from './basket.reducer';
import sessions from './sessions.reducer';
import dates from './dates.reducer';
import accommodation from './accommodation.reducer';
import paymentMethod from './paymentMethod.reducer';
import upselling from './upselling.reducer';
import sections from './sections.reducer';
import searchPanel from './searchPanel.reducer';
import cms from './cms.reducer';
import login from './login.reducer';
import customer from './customer.reducer';
import order from './order.reducer';
import users from './users.reducer';
import payment from './payment.reducer';
import attendee from './attendee.reducer';
import placement from './placement.reducer';
import barcodes from './barcodes.reducer';
import ErrorModel from '../../Models/ErrorModel';

export type reducerItemType = {
  [key: string]: any;
  loading: boolean;
  error: ErrorModel;
} | null;

const rootReducer = combineReducers({
  accommodation: accommodation,
  attendee: attendee,
  basket: basket,
  barcodes: barcodes,
  cms: cms,
  customer: customer,
  dates: dates,
  login: login,
  options: options,
  order: order,
  payment: payment,
  paymentMethod: paymentMethod,
  placement: placement,
  searchPanel: searchPanel,
  sections: sections,
  sessions: sessions,
  tickets: tickets,
  upselling: upselling,
  users: users,
});

export default rootReducer;
