import { customerConstants } from '../constants/customer.constants';
import CustomerFormModel from '../../Models/Form/CustomerFormModel';
import ErrorModel from '../../Models/ErrorModel';
import { reducerItemType } from './index';

const initialState: { [stateName: string]: reducerItemType } = {
  customerForm: null,
  newCustomerFrom: null,
};

const customer = (state = initialState, action: any) => {
  switch (action.type) {
    case customerConstants.GET_CUSTOMER_FORM_REQUEST: {
      return {
        ...state,
        customerForm: { form: [], error: null, loading: true },
      };
    }

    case customerConstants.GET_CUSTOMER_FORM_SUCCESS: {
      return {
        ...state,
        customerForm: {
          form: new CustomerFormModel(action.form),
          error: null,
          loading: false,
        },
      };
    }

    case customerConstants.GET_CUSTOMER_FORM_FAILURE: {
      return {
        ...state,
        customerForm: {
          form: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }

    case customerConstants.POST_CUSTOMER_FORM_REQUEST: {
      return {
        ...state,
        newCustomerFrom: { form: [], error: null, loading: true },
      };
    }

    case customerConstants.POST_CUSTOMER_FORM_SUCCESS: {
      return {
        ...state,
        newCustomerFrom: {
          form: action.response,
          error: null,
          loading: false,
        },
      };
    }

    case customerConstants.POST_CUSTOMER_FORM_FAILURE: {
      return {
        ...state,
        newCustomerFrom: {
          form: null,
          error: new ErrorModel(action.error),
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default customer;
