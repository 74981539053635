import QuestionModel from './Form/QuestionModel';

export default class BarcodeAttendeeModel {
  formId: number;
  questions: QuestionModel[];
  saleId: number;
  saleKey: string;
  saleName: string;

  constructor(data: any) {
    this.formId = data.formId;
    this.questions = data.questions
      ? data.questions.map((question: any) => new QuestionModel(question))
      : [];
    this.saleId = data.saleId;
    this.saleKey = data.saleKey;
    this.saleName = data.saleName;
  }
}
