import { getUrl } from './httpService';
import BarcodeModel from '../Models/BarcodeModel';

export const createBarcodeDownloadUrl = (
  url: string,
  barcode: BarcodeModel
) => {
  let newUrl = '';
  if (barcode.downloadHash && barcode.downloadLink) {
    newUrl = getUrl(url, {
      hash: barcode.downloadHash,
      link: barcode.downloadLink,
    }).toString();
  }
  return newUrl;
};
