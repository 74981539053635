export const barcodesConstants = {
  CLEAR_EXCHANGE_BARCODES: 'CLEAR_EXCHANGE_BARCODES',

  GET_BARCODES_LIST_REQUEST: 'GET_BARCODES_LIST_REQUEST',
  GET_BARCODES_LIST_SUCCESS: 'GET_BARCODES_LIST_SUCCESS',
  GET_BARCODES_LIST_FAILURE: 'GET_BARCODES_LIST_FAILURE',

  GET_EXCHANGE_BARCODES_REQUEST: 'GET_EXCHANGE_BARCODES_REQUEST',
  GET_EXCHANGE_BARCODES_SUCCESS: 'GET_EXCHANGE_BARCODES_SUCCESS',
  GET_EXCHANGE_BARCODES_FAILURE: 'GET_EXCHANGE_BARCODES_FAILURE',

  POST_EXCHANGE_BARCODE_REQUEST: 'POST_EXCHANGE_BARCODE_REQUEST',
  POST_EXCHANGE_BARCODE_SUCCESS: 'POST_EXCHANGE_BARCODE_SUCCESS',
  POST_EXCHANGE_BARCODE_FAILURE: 'POST_EXCHANGE_BARCODE_FAILURE',

  POST_FORWARD_BARCODE_REQUEST: 'POST_FORWARD_BARCODE_REQUEST',
  POST_FORWARD_BARCODE_SUCCESS: 'POST_FORWARD_BARCODE_SUCCESS',
  POST_FORWARD_BARCODE_FAILURE: 'POST_FORWARD_BARCODE_FAILURE',

  GET_BARCODE_ATTENDEE_REQUEST: 'GET_BARCODE_ATTENDEE_REQUEST',
  GET_BARCODE_ATTENDEE_SUCCESS: 'GET_BARCODE_ATTENDEE_SUCCESS',
  GET_BARCODE_ATTENDEE_FAILURE: 'GET_BARCODE_ATTENDEE_FAILURE',

  POST_BARCODE_ATTENDEE_REQUEST: 'POST_BARCODE_ATTENDEE_REQUEST',
  POST_BARCODE_ATTENDEE_SUCCESS: 'POST_BARCODE_ATTENDEE_SUCCESS',
  POST_BARCODE_ATTENDEE_FAILURE: 'POST_BARCODE_ATTENDEE_FAILURE',
};
